import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { memo } from 'react';

import { useScanDeviceActions } from '@hooks/api';
import type { ScanDeviceActions } from 'types/api';

export interface ScanDeviceActionButtonsProps {
  scanDeviceId: string;
}

export const ScanDeviceActionButtons: FC<ScanDeviceActionButtonsProps> = memo(
  function ScanDeviceActionButtons({ scanDeviceId }) {
    const { enqueueSnackbar } = useSnackbar();
    const { restart, shutdown, update } = useScanDeviceActions(scanDeviceId);

    const triggerAction = async (
      action: ScanDeviceActions,
      actionFunc: typeof restart.trigger | typeof shutdown.trigger | typeof update.trigger,
    ) => {
      const actionText = `${action.charAt(0).toUpperCase()}${action.slice(1)}`;
      const isUserSure = confirm(`${actionText} this ScanDevice?`);

      if (!isUserSure) {
        return;
      }

      try {
        await actionFunc();

        enqueueSnackbar(`${actionText} triggered successfully!`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar((error as Error).toString(), { autoHideDuration: 5000, variant: 'error' });
      }
    };

    return (
      <Grid container spacing={1}>
        <Grid item>
          <LoadingButton
            color="primary"
            loading={restart.isMutating}
            onClick={() => triggerAction('restart', restart.trigger)}
            size="small"
            startIcon={<RestartAltOutlinedIcon />}
            variant="outlined"
          >
            Restart
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton
            color="primary"
            loading={shutdown.isMutating}
            onClick={() => triggerAction('shutdown', shutdown.trigger)}
            size="small"
            startIcon={<PowerSettingsNewOutlinedIcon />}
            variant="outlined"
          >
            Shutdown
          </LoadingButton>
        </Grid>
      </Grid>
    );
  },
);
