import DarkModeIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeIcon from '@mui/icons-material/LightModeOutlined';
import { IconButton, useTheme } from '@mui/material';
import { FC, useContext } from 'react';

import { ColorModeContext } from '@contexts/ColorModeContext';

export const ThemeSwitcher: FC = () => {
  const {
    palette: { mode },
  } = useTheme();
  const { changeColorMode } = useContext(ColorModeContext);

  const handleClick = () => {
    if (changeColorMode) {
      changeColorMode(mode === 'light' ? 'dark' : 'light');
    }
  };

  return (
    <IconButton
      color="inherit"
      data-testid="theme-switcher-button"
      id="theme-switcher-button"
      onClick={handleClick}
    >
      {mode === 'light' ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );
};
