import { Skeleton } from '@mui/material';
import type { FC } from 'react';

import { SectionRow, type SectionRowProps } from './SectionRow';

export interface SectionRowWithLoadingProps extends SectionRowProps {
  loading?: boolean;
}

export const SectionRowWithLoading: FC<SectionRowWithLoadingProps> = ({
  children,
  loading,
  ...props
}) => {
  return (
    <SectionRow {...props}>
      {loading ? (
        <Skeleton sx={{ maxWidth: (theme) => theme.spacing(24) }} variant="rectangular" />
      ) : (
        children
      )}
    </SectionRow>
  );
};
