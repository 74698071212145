import { useEnvironment } from '@hooks/useEnvironment';

export const useDatadog = () => {
  const { datadogUrl } = useEnvironment();

  const getDataDogLink = (udid: string) => {
    const fromTs = new Date().getTime();
    // 1 week ago
    const toTs = fromTs + 1000 * 60 * 60 * 24 * 7;

    return `${datadogUrl}/logs?query=%40usr.udid%3A${udid}&cols=host%2Cservice&index=&messageDisplay=inline&stream_sort=desc&from_ts=${fromTs}&to_ts=${toTs}&live=false`;
  };

  return {
    getDataDogLink,
  };
};
