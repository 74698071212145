import useSWRMutation from 'swr/mutation';

import { useEnvironment } from '@hooks/useEnvironment';
import { ScanDevicesResetResponse } from 'types/api/scan-devices/scan-devices-reset-reponse';
import { ScanDevicesResetRequest } from 'types/api/scan-devices/scan-devices-reset-request';

import { useMutationFetcher, MutationFetcherKey } from '../useMutationFetcher';

export const useScanDeviceReset = () => {
  const mutationFetcher = useMutationFetcher<ScanDevicesResetResponse, ScanDevicesResetRequest>();
  const { apiUrlV3 } = useEnvironment();

  return useSWRMutation<
    ScanDevicesResetResponse,
    Error,
    MutationFetcherKey,
    ScanDevicesResetRequest
  >([`${apiUrlV3}/devices/reset`, { method: 'POST' }], mutationFetcher);
};
