import LinearProgress from '@mui/material/LinearProgress';
import { GridOverlay } from '@mui/x-data-grid';
import type { FC } from 'react';

import * as classes from './CustomLoadingOverlay.module.css';

export const CustomLoadingOverlay: FC = () => {
  return (
    <GridOverlay>
      <div className={classes.progressContainer}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};
