import DoNotDisturbRoundedIcon from '@mui/icons-material/DoNotDisturbRounded';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback } from 'react';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';

import { UseMdmInfoMutate, useScanDeviceUpdate } from '@hooks/api';
import { useDeviceSupportForInternalFeatureFlags } from '@hooks/useDeviceSupportForInternalFeatureFlags';
import { useInPlaceEdit } from '@hooks/useInPlaceEdit';
import type { ScanDeviceDetail, ScanDeviceMdmInfo } from 'types/api';

import { ControlledSwitchWithInPlaceEdit } from './ControlledSwitchWithInPlaceEdit';
import { InPlaceEdit } from './InPlaceEdit';

interface FormValues {
  internalFeaturesAvailable: boolean;
}

export interface UnlockInternalFeaturesInPlaceChangeButtonProps {
  mutateScanDeviceMdmInfo: UseMdmInfoMutate;
  scanDeviceDetail: ScanDeviceDetail;
  scanDeviceMdmInfo?: ScanDeviceMdmInfo;
}

export const UnlockInternalFeaturesInPlaceChangeButton: FC<
  UnlockInternalFeaturesInPlaceChangeButtonProps
> = ({ mutateScanDeviceMdmInfo, scanDeviceDetail, scanDeviceMdmInfo }) => {
  const { trigger, isMutating } = useScanDeviceUpdate(scanDeviceDetail.id);
  const deviceSupportsInternalFeatureFlags =
    useDeviceSupportForInternalFeatureFlags(scanDeviceMdmInfo);

  const update = useCallback(
    async (data: FormValues) => {
      if (!scanDeviceMdmInfo) return;

      await trigger(data);

      mutateScanDeviceMdmInfo((current) => {
        if (!current) return current;

        return {
          ...current,
          ...data,
        };
      });
    },
    [mutateScanDeviceMdmInfo, trigger],
  );

  const { isEditing, onSubmit, setIsEditing } = useInPlaceEdit<FormValues>({
    update,
  });

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { internalFeaturesAvailable: scanDeviceMdmInfo?.internalFeaturesAvailable },
  });

  const onChangeHandler = handleSubmit(onSubmit);

  if (!deviceSupportsInternalFeatureFlags) {
    return (
      <Grid alignItems="center" container spacing={2}>
        <Grid item>
          <DoNotDisturbRoundedIcon color="warning" data-testid="remote-update-unavailable-icon" />
        </Grid>
        <Grid item>Unable to support internal feature flag control (requires v219.1+)</Grid>
      </Grid>
    );
  }

  return (
    <InPlaceEdit
      disabled={false}
      isEditing={isEditing}
      isUpdating={isMutating}
      selectedValueText={scanDeviceMdmInfo?.internalFeaturesAvailable ? 'Yes' : 'No'}
      setIsEditing={setIsEditing}
    >
      <Box sx={{ marginLeft: (theme) => theme.spacing(1) }}>
        <ControlledSwitchWithInPlaceEdit
          controllerProps={{ control, name: 'internalFeaturesAvailable' }}
          formControlProps={{
            fullWidth: false,
            margin: 'none',
          }}
          switchProps={{
            ariaLabel: `${
              scanDeviceMdmInfo?.internalFeaturesAvailable ? 'Disable' : 'Enable'
            } internal deepscan features`,
            title: 'Internal DeepScan features available?',
            onChange: onChangeHandler,
          }}
        />
      </Box>
    </InPlaceEdit>
  );
};
