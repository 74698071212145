import { useCallback } from 'react';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';

import { ControlledSelect } from '@components/Form';
import { UseScanDeviceMutate, useScanDeviceUpdate } from '@hooks/api';
import { useHardwareVersionSelect } from '@hooks/useHardwareVersionSelect';
import { useInPlaceEdit } from '@hooks/useInPlaceEdit';
import type { ScanDeviceDetail } from 'types/api';

import { InPlaceEdit } from './InPlaceEdit';

interface FormValues {
  hardwareVersion: string;
}

export interface HardwareVersionInPlaceChangeButtonProps {
  mutateScanDeviceDetail: UseScanDeviceMutate;
  scanDeviceDetail: ScanDeviceDetail;
}

export const HardwareVersionInPlaceChangeButton: FC<HardwareVersionInPlaceChangeButtonProps> = ({
  mutateScanDeviceDetail,
  scanDeviceDetail,
}) => {
  const { trigger, isMutating } = useScanDeviceUpdate(scanDeviceDetail.id);
  const { options } = useHardwareVersionSelect();

  const update = useCallback(
    async (data: FormValues) => {
      await trigger(data, {
        populateCache: true,
      });

      mutateScanDeviceDetail((current) => {
        if (!current) return current;

        const hardwareVersion = options.find(
          (hardwareVersionOption) => hardwareVersionOption.id === data.hardwareVersion,
        );

        return {
          ...current,
          hardwareVersion: hardwareVersion || current.hardwareVersion,
        };
      });
    },
    [mutateScanDeviceDetail, options, trigger],
  );

  const { isEditing, onSubmit, setIsEditing } = useInPlaceEdit<FormValues>({
    update,
  });

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { hardwareVersion: scanDeviceDetail.hardwareVersion.id },
  });

  const onChangeHandler = handleSubmit(onSubmit);

  return (
    <InPlaceEdit
      isEditing={isEditing}
      isUpdating={isMutating}
      selectedValueText={scanDeviceDetail.hardwareVersion.name}
      setIsEditing={setIsEditing}
    >
      <ControlledSelect
        controllerProps={{ control, name: 'hardwareVersion' }}
        options={options}
        textFieldProps={{
          onChange: onChangeHandler,
          size: 'small',
        }}
      />
    </InPlaceEdit>
  );
};
