import { Alert, Box, Button, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

import { useScanDeviceReset } from '@hooks/api/scan-devices/useScanDeviceReset';

import { PreviewAreaStyle, ResetButtonAreaStyle } from './styles';

export type Props = {
  file: File;
  onResetTriggered: () => void;
};

export const Content = ({ file, onResetTriggered }: Props) => {
  const { data, trigger: resetDevices, error, isMutating } = useScanDeviceReset();
  const [devicesToReset, setDevicesToReset] = useState<string[]>([]);

  useEffect(() => {
    const reader = new FileReader();

    reader.onload = ({ target }) => {
      setDevicesToReset(
        (target?.result as string | null)
          ?.trim()
          .split('\n')
          .map((deviceId) => deviceId.trim()) ?? [],
      );
    };

    reader.readAsText(file);
  }, [file]);

  useEffect(() => {
    if (!error && data?.idsNotFound.length === 0) {
      onResetTriggered();
    }
  }, [error, data]);

  if (devicesToReset.length === 0) {
    return <div>File does not contain devices to reset.</div>;
  }

  const handleReset = async () => {
    try {
      await resetDevices({ ids: devicesToReset });
    } catch (error) {
      // do nothing, it's evaluated as error by SWR mutation hook
    }
  };

  return (
    <>
      <Box height="16px"></Box>
      <Typography variant="h6">
        {devicesToReset.length > 1
          ? `${devicesToReset.length} Devices will be reset:`
          : 'One Device will be reset:'}
      </Typography>
      <Box height="8px"></Box>
      <Box sx={PreviewAreaStyle}>
        {devicesToReset.map((deviceToReset) => (
          <pre key={deviceToReset} style={{ margin: 0, fontSize: '12px' }}>
            {deviceToReset}
          </pre>
        ))}
      </Box>
      <Box height="16px"></Box>
      <Box sx={ResetButtonAreaStyle}>
        <Button
          color="primary"
          disabled={isMutating}
          onClick={async () => {
            await handleReset();
          }}
          variant="outlined"
        >
          Reset Devices
        </Button>
      </Box>
      {error && (
        <>
          <Box height="16px"></Box>
          <Alert severity="error">{error.toString()}</Alert>
        </>
      )}
      {data?.idsNotFound && (
        <>
          <Box height="16px"></Box>
          <Alert severity="warning">
            IDs not found:
            {data.idsNotFound.map((idNotFound) => (
              <pre key={idNotFound} style={{ margin: 0, fontSize: '12px' }}>
                {idNotFound}
              </pre>
            ))}
          </Alert>
        </>
      )}
    </>
  );
};
