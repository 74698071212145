import { Button, Grid, Paper } from '@mui/material';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';

import { allSelectOption } from '@components/Form';
import { useSearchFilterForm } from '@hooks/useSearchFilterForm';
import type { TableFilters } from '@hooks/useTableFilterSort';

import { SearchFilterControlledClientOrganizationSelect } from './SearchFieldControlledClientOrganizationSelect';
import { SearchFilterControlledTextField } from './SearchFilterControlledTextField';
import { SearchFilterControlledVrsTypeSelect } from './SearchFilterControlledVrsTypeSelect';

export interface SearchFilterFormValues {
  name?: string;
  vrsType?: string;
  clientOrganization?: string;
}

const defaultValues: SearchFilterFormValues = {
  name: '',
  vrsType: allSelectOption.id,
  clientOrganization: allSelectOption.id,
};

export interface SearchFilterProps {
  initialValues?: Partial<SearchFilterFormValues>;
  onChange: (values: TableFilters) => void;
}

export const ScanDevicesTableSearchFilter: FC<SearchFilterProps> = ({
  initialValues,
  onChange,
}) => {
  const { control, ...useFormProps } = useForm<SearchFilterFormValues>({
    defaultValues: { ...defaultValues, ...initialValues },
  });

  const { debouncedOnChangeHandler, onChangeHandler, onResetButtonClickHandler } =
    useSearchFilterForm<SearchFilterFormValues>({
      defaultValues,
      onChange,
      ...useFormProps,
    });

  return (
    <Paper sx={{ p: 1 }}>
      <form>
        <Grid container spacing={2}>
          <Grid item lg={7} md={7} sm={4} xs={12}>
            <SearchFilterControlledTextField
              controllerProps={{ control, name: 'name' }}
              textFieldProps={{ label: 'Search by name', onChange: debouncedOnChangeHandler }}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={4} xs={12}>
            <SearchFilterControlledVrsTypeSelect
              controllerProps={{ control, name: 'vrsType' }}
              textFieldProps={{
                label: 'VRS Type',
                onChange: onChangeHandler,
              }}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={4} xs={12}>
            <SearchFilterControlledClientOrganizationSelect
              controllerProps={{ control, name: 'clientOrganization' }}
              textFieldProps={{
                label: 'Client',
                onChange: onChangeHandler,
              }}
            />
          </Grid>
          <Grid flex={1} item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onResetButtonClickHandler}>Clear</Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
