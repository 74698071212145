import { useMemo } from 'react';

import type { ControlledSelectOption } from '@components/Form';

import { useHardwareVersions } from './api';

export const useHardwareVersionSelect = (
  buildOptionsFunc?: (options?: ControlledSelectOption[]) => ControlledSelectOption[],
) => {
  const { data: hardwareVersions } = useHardwareVersions({});

  return useMemo(() => {
    const options = hardwareVersions?.content ?? [];

    return {
      options: buildOptionsFunc ? buildOptionsFunc(options) : options,
    };
  }, [hardwareVersions]);
};
