import useSWR, { KeyedMutator, type SWRConfiguration } from 'swr';

import { useEnvironment } from '@hooks/useEnvironment';
import type { ScanDeviceMdmInfo } from 'types/api';

import { type JsonFetcherArgs, useJsonFetcher } from '../useJsonFetcher';

export type UseMdmInfoMutate = KeyedMutator<ScanDeviceMdmInfo>;

export const useMdmInfo = (id: string, swrConfig?: SWRConfiguration) => {
  const jsonFetcher = useJsonFetcher<ScanDeviceMdmInfo>();
  const { apiUrl } = useEnvironment();

  const key = id ? `${apiUrl}/devices/${id}/live` : null;
  const { data, error, mutate } = useSWR<ScanDeviceMdmInfo>(
    [key] as JsonFetcherArgs,
    jsonFetcher,
    swrConfig,
  );

  return {
    data,
    isLoading: key ? !error && !data : false,
    error,
    mutate,
  };
};
