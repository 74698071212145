import { useMemo } from 'react';

import type { ControlledSelectOption } from '@components/Form';

import { useClientOrganizations } from './api';

export const useClientOrganizationSelect = (
  buildOptionsFunc?: (options?: ControlledSelectOption[]) => ControlledSelectOption[],
) => {
  const { data: clients, isLoading } = useClientOrganizations();

  return useMemo(() => {
    const options = clients ?? [];

    return {
      options: buildOptionsFunc ? buildOptionsFunc(options) : options,
      isLoading,
    };
  }, [clients]);
};
