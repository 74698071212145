/**
 * Based on backoffice frontend table component
 */
import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { DataGrid, GridDensity } from '@mui/x-data-grid';
import type {
  GridSortDirection,
  GridColumns,
  GridRowsProp,
  GridSortModel,
  GridInitialState,
  GridEventListener,
  GridEvents,
  GridSelectionModel,
  GridSlotsComponent,
  GridColumnVisibilityModel,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useRef, FC, useEffect } from 'react';

import { useLocalStorage } from '@hooks/useLocalStorage';
import type { TableFilterSortHookReturn } from '@hooks/useTableFilterSort';

import { CustomLoadingOverlay } from './CustomLoadingOverlay';
import { CustomToolbar } from './CustomToolbar';
export interface TableProps {
  autoPageSize?: boolean;
  checkboxSelection?: boolean;
  columns: GridColumns;
  columnVisibilityModel?: GridColumnVisibilityModel;
  components?: Partial<GridSlotsComponent>;
  componentsProps?: GridSlotsComponentsProps;
  density?: GridDensity;
  disableSelectionOnClick?: boolean;
  error?: Error;
  initialState?: GridInitialState;
  loading?: boolean;
  localStorageSettingsKey: string;
  onRowClick?: GridEventListener<GridEvents.rowClick>;
  rows?: GridRowsProp;
  rowCount?: number;
  sortingOrder?: GridSortDirection[];
  tableFilterSort: TableFilterSortHookReturn;
}

export const Table: FC<TableProps> = ({
  checkboxSelection,
  columnVisibilityModel: initialColumnVisibilityModel,
  components,
  density = 'compact',
  disableSelectionOnClick,
  error,
  loading,
  localStorageSettingsKey,
  rowCount,
  rows = [],
  sortingOrder = ['asc', 'desc'],
  tableFilterSort,
  ...gridProps
}) => {
  const {
    setPageNumber,
    setSelectedRowIds,
    setTableSorting,
    setPageSize,
    page,
    selectedRowIds,
    sort: tableSorting,
  } = tableFilterSort;

  const { enqueueSnackbar } = useSnackbar();
  const cachedRowCount = useRef<number>(rowCount ?? 0);
  const [storedSettings, setStoredSettings] = useLocalStorage<{
    columnVisibilityModel?: GridColumnVisibilityModel;
  }>(localStorageSettingsKey, { columnVisibilityModel: initialColumnVisibilityModel });

  // This keeps the row count for the pagination when it gets unset because new data is being fetched
  if (rowCount !== undefined) {
    cachedRowCount.current = rowCount;
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.toString(), {
        preventDuplicate: true,
        variant: 'error',
      });
    }
  }, [error]);

  const onSortModelChange = (sortModel: GridSortModel): void => {
    if (!sortModel[0]) {
      setTableSorting(undefined);

      return;
    }
    const { sort, field } = sortModel[0];

    // empty sorting direction is ignored!
    if (!sort) return;

    setTableSorting({ field, sort });
  };

  const onSelectionModelChange = (selectionModel: GridSelectionModel): void => {
    let selectedIds: string[] = selectionModel as string[];

    if (!disableSelectionOnClick && !checkboxSelection && selectedRowIds[0] === selectedIds[0]) {
      selectedIds = [];
    }

    setSelectedRowIds(selectedIds);
  };

  const onColumnVisibilityModelChange = (
    columnVisibilityModel: GridColumnVisibilityModel,
  ): void => {
    setStoredSettings({ ...storedSettings, columnVisibilityModel });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <Box px={1}>
        {cachedRowCount === undefined ? (
          <Skeleton
            sx={(theme) => ({
              height: theme.spacing(3.5),
              marginBottom: theme.spacing(0.75),
              maxWidth: { xs: '50%', md: '30%', lg: '20%', xl: '15%' },
            })}
            variant="text"
          />
        ) : (
          <Typography gutterBottom variant="subtitle1">
            {`${cachedRowCount.current} ${
              cachedRowCount.current === 1 ? 'item' : 'items'
            } in total`}
          </Typography>
        )}
      </Box>
      <Paper
        sx={{
          height: '100%',
        }}
      >
        <DataGrid
          autoPageSize
          checkboxSelection={checkboxSelection}
          columnVisibilityModel={storedSettings.columnVisibilityModel}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
            Toolbar: CustomToolbar,
            ...components,
          }}
          density={density}
          disableSelectionOnClick={disableSelectionOnClick}
          loading={loading}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          onPageChange={setPageNumber}
          onPageSizeChange={setPageSize}
          onSelectionModelChange={onSelectionModelChange}
          onSortModelChange={onSortModelChange}
          page={page}
          pagination
          paginationMode="server"
          rowCount={cachedRowCount.current}
          rows={rows}
          selectionModel={selectedRowIds as GridSelectionModel}
          showCellRightBorder
          showColumnRightBorder
          sortModel={tableSorting ? [tableSorting] : []}
          sortingMode="server"
          sortingOrder={sortingOrder}
          sx={{
            '& .MuiDataGrid-row:hover': {
              cursor: gridProps.onRowClick ? 'pointer' : 'default',
            },
          }}
          {...gridProps}
        />
      </Paper>
    </Box>
  );
};
