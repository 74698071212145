import { ControlledSelect, addAllSelectOption } from '@components/Form';
import type { ControlledSelectProps } from '@components/Form';
import { useClientOrganizationSelect } from '@hooks/useClientSelect';

import type { SearchFilterFormValues } from './ScanDevicesTableSearchFilter';

export type ControlledClientOrganizationSelectProps = Omit<
  ControlledSelectProps<SearchFilterFormValues>,
  'options'
>;

export const SearchFilterControlledClientOrganizationSelect = ({
  textFieldProps,
  ...props
}: ControlledClientOrganizationSelectProps) => {
  const { options, isLoading } = useClientOrganizationSelect(addAllSelectOption);

  if (isLoading) return null;

  return (
    <ControlledSelect
      {...props}
      options={options}
      textFieldProps={{ ...textFieldProps, size: 'small' }}
    />
  );
};
