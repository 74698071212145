import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, type TextFieldProps } from '@mui/material';
import { useState } from 'react';
import type { FieldValues, ControllerProps } from 'react-hook-form';

import { ControlledTextField } from './ControlledTextField';

export interface ControlledPasswordFieldProps<FormValuesType extends FieldValues> {
  controllerProps: Omit<ControllerProps<FormValuesType>, 'render'>;
  textFieldProps: TextFieldProps;
}

export const ControlledPasswordField = <FormValuesType extends FieldValues>({
  controllerProps,
  textFieldProps,
}: ControlledPasswordFieldProps<FormValuesType>): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleVisibility = () => setShowPassword(!showPassword);

  return (
    <ControlledTextField
      controllerProps={controllerProps}
      textFieldProps={{
        ...textFieldProps,
        type: showPassword ? 'text' : 'password',
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={toggleVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};
