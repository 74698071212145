import {
  Checkbox,
  type CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { Controller, type FieldValues, type ControllerProps } from 'react-hook-form';

import { FormErrorMessage } from './FormErrorMessage';

export interface ControlledCheckboxProps<FormValuesType extends FieldValues> {
  controllerProps: Omit<ControllerProps<FormValuesType>, 'render'>;
  checkboxProps: CheckboxProps & { helperText?: string; label?: string };
}

export const ControlledCheckbox = <FormValuesType extends FieldValues>({
  controllerProps,
  checkboxProps: { helperText = '', label = '', ...checkboxProps },
}: ControlledCheckboxProps<FormValuesType>): JSX.Element => (
  <Controller
    {...controllerProps}
    render={({ field, fieldState: { error } }) => (
      <FormControl
        error={error !== undefined}
        fullWidth
        required={controllerProps.rules?.required !== undefined}
      >
        <FormControlLabel
          control={
            <Checkbox
              required={controllerProps.rules?.required !== undefined}
              {...field}
              {...checkboxProps}
              checked={field.value}
              onChange={(event, checked) => {
                field.onChange(event);
                checkboxProps.onChange && checkboxProps.onChange(event, checked);
              }}
            />
          }
          label={label}
        />
        {(helperText || error) && (
          <FormHelperText>{error ? <FormErrorMessage error={error} /> : helperText}</FormHelperText>
        )}
      </FormControl>
    )}
  />
);
