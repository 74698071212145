import type { FilterSortProps } from '@hooks/useTableFilterSort';
import type { PaginatedServerResponse } from 'types/api';

import { SwrConfig, useScanDeviceApiGetQuery } from './useScanDeviceApiGetQuery';

const buildFilterSortUrlSearchParams = ({
  filters = {},
  page = 0,
  size = 10,
  sort,
}: Partial<FilterSortProps>) => {
  const searchParams = new URLSearchParams({
    page: page?.toString(),
    size: size?.toString(),
    ...filters,
  });

  if (sort?.field) {
    searchParams.append('sort', `${sort.field},${sort.sort}`);
  }

  return searchParams;
};

export const useScanDeviceApiListQuery = <ResponseType>(apiPath: string) => {
  return (
    filterSortProps: Partial<FilterSortProps>,
    swrConfig?: SwrConfig<PaginatedServerResponse<ResponseType>>,
  ) => {
    const searchParams = buildFilterSortUrlSearchParams(filterSortProps);
    const swrResponse = useScanDeviceApiGetQuery<PaginatedServerResponse<ResponseType>>(
      `${apiPath}?${searchParams.toString()}`,
      swrConfig,
    );

    return swrResponse;
  };
};
