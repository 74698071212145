import './utils/sentrySetup';
import { createRoot } from 'react-dom/client';

import '@styles/global.module.css';

import { App } from '@components/App';

const rootElement = document.getElementById('root') as Element;
const root = createRoot(rootElement);

root.render(<App />);
