import type { FC } from 'react';

import { toLocaleString } from '@components/Util/toLocaleString';
import type { ScanDeviceDetail, ScanDeviceMdmInfo } from 'types/api';

import { LastKnownAddress } from './LastKnownAddress';
import { LastKnownLocationMap } from './LastKnownLocationMap';
import { Section } from './Section';
import { SectionRow } from './SectionRow';
import { SectionRowWithLoading } from './SectionRowWithLoading';

export interface GeneralInformationSectionProps {
  mdmInfo?: ScanDeviceMdmInfo;
  mdmInfoIsLoading: boolean;
  scanDeviceDetail: ScanDeviceDetail;
}

export const GeneralInformationSection: FC<GeneralInformationSectionProps> = ({
  mdmInfoIsLoading,
  mdmInfo,
  scanDeviceDetail,
}) => {
  return (
    <Section title="General Information">
      <SectionRow label="Name">{scanDeviceDetail.name}</SectionRow>
      <SectionRow label="ID">{scanDeviceDetail.id}</SectionRow>
      <SectionRow label="UDID">{scanDeviceDetail.udid}</SectionRow>
      <SectionRow label="MDMID">{scanDeviceDetail.mdmId}</SectionRow>
      <SectionRowWithLoading label="MDM last seen Device at" loading={mdmInfoIsLoading}>
        {toLocaleString(mdmInfo?.lastSeenAt)}
      </SectionRowWithLoading>
      <SectionRow label="Last known Location updated at">
        {toLocaleString(scanDeviceDetail.metrics?.time)}
      </SectionRow>
      <SectionRow label="Last known Location">
        <LastKnownLocationMap {...scanDeviceDetail.metrics?.location} />
      </SectionRow>
      <SectionRow label="Last known Address">
        <LastKnownAddress {...scanDeviceDetail.metrics?.location} />
      </SectionRow>
    </Section>
  );
};
