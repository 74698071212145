import type { PaletteMode } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useState, useMemo, useEffect } from 'react';

import { getDesignTokens } from '@styles/theme';

export const useSetupAppTheme = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState<PaletteMode>('dark');
  const colorMode = useMemo(
    () => ({
      changeColorMode: (mode: PaletteMode) => {
        setMode(mode);
      },
    }),
    [],
  );

  useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode]);

  // Update the theme only if the mode changes
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return {
    colorMode,
    theme,
  };
};
