import { Container } from '@mui/material';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { AppBar } from './AppBar';

export const Layout: FC = () => {
  return (
    <>
      <AppBar />
      <Container
        component="main"
        maxWidth="xl"
        sx={{
          py: (theme) => theme.spacing(4),
        }}
      >
        <Outlet />
      </Container>
    </>
  );
};
