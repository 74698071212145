export const toLocaleString = (dateString?: string | null) => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);

  return date.toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};
