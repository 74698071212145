import type { GridColDef, GridSortDirection } from '@mui/x-data-grid';

import type { TableProps } from '@components/Table';
import type { FilterSortProps } from '@hooks/useTableFilterSort';
import { renderDate } from '@lib/tableCellRenderers';

export const initialTableFilterSortArgs: Partial<FilterSortProps> = {
  sort: { field: 'createdDate', sort: 'desc' as GridSortDirection },
};

export const columns: GridColDef[] = [
  {
    disableColumnMenu: true,
    field: 'createdDate',
    filterable: false,
    headerName: 'Created At',
    hideable: false,
    minWidth: 150,
    sortable: true,
    width: 150,
    flex: 1,
    type: 'string',
    renderCell: renderDate,
  },
  {
    disableColumnMenu: true,
    field: 'shortVersion',
    filterable: false,
    headerName: 'Short Version',
    hideable: false,
    minWidth: 150,
    sortable: true,
    flex: 1,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'buildVersion',
    filterable: false,
    headerName: 'Build Version',
    hideable: true,
    width: 200,
    sortable: true,
    flex: 1,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'hasCriticalBug',
    filterable: false,
    headerName: 'Has Critical Bug',
    sortable: true,
    width: 150,
    flex: 1,
    type: 'string',
    renderCell: ({ value }) => (value ? 'Yes' : 'No'),
  },
];

export const columnVisibilityModelDesktop: TableProps['columnVisibilityModel'] = {};

export const columnVisibilityModelMobile: TableProps['columnVisibilityModel'] = {};
