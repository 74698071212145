import type { GridColDef, GridRenderCellParams, GridSortDirection } from '@mui/x-data-grid';

import type { TableProps } from '@components/Table';
import type { FilterSortProps } from '@hooks/useTableFilterSort';
import type { HardwareVersion } from 'types/api';

export const initialTableFilterSortArgs: Partial<FilterSortProps> = {
  sort: { field: 'name', sort: 'asc' as GridSortDirection },
};

const addMeters = ({ value }: GridRenderCellParams<number, HardwareVersion>): string => `${value}m`;

export const columns: GridColDef[] = [
  {
    disableColumnMenu: true,
    field: 'name',
    filterable: false,
    headerName: 'Name',
    hideable: false,
    minWidth: 200,
    sortable: true,
    flex: 1,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'id',
    filterable: false,
    headerName: 'ID',
    hideable: true,
    width: 295,
    sortable: true,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'antennaType',
    filterable: false,
    headerName: 'Antenna Type',
    sortable: true,
    width: 300,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'antennaOffsetX',
    filterable: false,
    headerName: 'Antenna Offset X',
    sortable: true,
    width: 130,
    type: 'string',
    renderCell: addMeters,
  },
  {
    disableColumnMenu: true,
    field: 'antennaOffsetY',
    filterable: false,
    headerName: 'Antenna Offset Y',
    sortable: true,
    width: 130,
    type: 'string',
    renderCell: addMeters,
  },
  {
    disableColumnMenu: true,
    field: 'antennaOffsetZ',
    filterable: false,
    headerName: 'Antenna Offset Z',
    sortable: true,
    width: 130,
    type: 'string',
    renderCell: addMeters,
  },
  {
    disableColumnMenu: true,
    field: 'isDefault',
    filterable: false,
    headerName: 'Default',
    sortable: true,
    width: 80,
    type: 'string',
    renderCell: ({ value }) => (value ? 'Yes' : 'No'),
  },
];

export const columnVisibilityModelDesktop: TableProps['columnVisibilityModel'] = {};

export const columnVisibilityModelMobile: TableProps['columnVisibilityModel'] = {
  antennaOffsetX: false,
  antennaOffsetY: false,
  antennaOffsetZ: false,
  id: false,
};
