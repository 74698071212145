import useSWRMutation from 'swr/mutation';

import { useEnvironment } from '@hooks/useEnvironment';

import { MutationFetcherKey, useMutationFetcher } from './useMutationFetcher';

export const useScanDeviceApiDelete = <ResponseType, Inputs = void>(apiPath: string) => {
  return (id?: string) => {
    const mutationFetcher = useMutationFetcher<ResponseType, Inputs>();
    const { apiUrl } = useEnvironment();

    return useSWRMutation<ResponseType, Error, MutationFetcherKey, Inputs>(
      id ? [`${apiUrl}${apiPath}/${id}`, { method: 'DELETE' }] : null,
      mutationFetcher,
    );
  };
};
