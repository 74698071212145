import { Grid, Theme, useMediaQuery } from '@mui/material';
import type { FC } from 'react';

import { MenuButton } from '@components/MenuButton';

import { AppBarNavLink } from './AppBarNavLink';

export const appBarNavigationItems = [
  { name: 'Devices', to: '/devices', matchPattern: '/devices/*' },
  { name: 'VRS Accounts', to: '/vrs-accounts', matchPattern: '/vrs-accounts/*' },
  { name: 'VRS Types', to: '/vrs-types', matchPattern: '/vrs-types/*' },
  { name: 'Hardware Versions', to: '/hardware-versions', matchPattern: '/hardware-versions/*' },
  { name: 'DeepScan Versions', to: '/deepscan-versions', matchPattern: '/deepscan-versions/*' },
];

export const AppBarNavigation: FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <MenuButton
        buttonProps={{
          color: 'inherit',
          sx: (theme) => ({
            padding: theme.spacing(1, 3),
            '&:hover, &:focus': {
              color: theme.palette.primary.main,
            },
            textTransform: 'none',
          }),
          variant: 'text',
        }}
        id="navigation-menu"
        label="Menu"
      >
        {(handleClose) =>
          appBarNavigationItems.map(({ name, ...rest }) => (
            <AppBarNavLink {...rest} isMobile={isMobile} key={name} onClick={handleClose}>
              {name}
            </AppBarNavLink>
          ))
        }
      </MenuButton>
    );
  }

  return (
    <Grid
      alignItems="center"
      container
      data-testid="app-bar-navigation-container"
      justifyContent="center"
      spacing={3}
    >
      {appBarNavigationItems.map(({ name, ...rest }) => (
        <Grid item key={name}>
          <AppBarNavLink {...rest} isMobile={isMobile}>
            {name}
          </AppBarNavLink>
        </Grid>
      ))}
    </Grid>
  );
};
