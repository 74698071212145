import useSWR from 'swr';

import { useEnvironment } from '@hooks/useEnvironment';

interface MapBoxPlacesResponse {
  features: Array<{
    place_name: string;
  }>;
}

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export const useMapboxAddress = (lat?: number | null, long?: number | null) => {
  const { mapboxApiToken } = useEnvironment();

  const mapboxBaseApiUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
  const swrKey =
    lat && long
      ? `${mapboxBaseApiUrl}/${long},${lat}.json?access_token=${mapboxApiToken}&language=${navigator.language}`
      : null;

  const { data, error, mutate } = useSWR<MapBoxPlacesResponse>(swrKey, fetcher, {
    revalidateOnFocus: false,
  });

  const address = data?.features[0]?.place_name;

  return {
    address,
    isLoading: swrKey ? !error && !data : false,
    error,
    mutate,
  };
};
