import useSWRMutation from 'swr/mutation';

import { useEnvironment } from '@hooks/useEnvironment';

import { MutationFetcherKey, useMutationFetcher } from './useMutationFetcher';

export const useScanDeviceApiUpsert = <ResponseType, Inputs>(apiPath: string) => {
  return (id?: string, fetchInit?: RequestInit | undefined) => {
    const mutationFetcher = useMutationFetcher<ResponseType, Inputs>();
    const { apiUrl } = useEnvironment();

    return useSWRMutation<ResponseType, Error, MutationFetcherKey, Inputs>(
      [`${apiUrl}${apiPath}${id ? '/' + id : ''}`, { method: id ? 'PATCH' : 'POST', ...fetchInit }],
      mutationFetcher,
    );
  };
};
