import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import SecurityUpdateOutlinedIcon from '@mui/icons-material/SecurityUpdateOutlined';
import { Button, Grid, LinearProgress, Paper } from '@mui/material';
import * as React from 'react';

import { getLocalizedDateString } from '@lib/tableCellRenderers';
import type { PhasedRollout } from 'types/api';

export interface PhasedRolloutControlProps {
  onDelete: () => void;
  onOpen: () => void;
  phasedRollout?: Partial<PhasedRollout>;
}

export const PhasedRolloutControl: React.FC<PhasedRolloutControlProps> = React.memo(
  function PhasedRolloutControl({ onDelete, onOpen, phasedRollout }) {
    const { deviceProgressCount = 0, devicesCount = 0, id, fullRolloutAfter } = phasedRollout || {};
    const progress = !devicesCount ? 0 : Math.floor((deviceProgressCount / devicesCount) * 100);

    return (
      <Paper sx={{ overflow: 'hidden', width: '100%' }}>
        {id && <LinearProgress color="inherit" value={progress} variant="determinate" />}
        <Grid
          alignItems="center"
          container
          justifyContent="flex-end"
          mb={0.5}
          p={1.5}
          pt={id ? undefined : 2}
          spacing={3}
        >
          {id ? (
            <>
              <Grid alignItems="center" display="flex" item>
                <PlayCircleFilledWhiteOutlinedIcon sx={{ mr: 0.5 }} />
                {getLocalizedDateString(fullRolloutAfter)}
              </Grid>
              <Grid alignItems="center" display="flex" item>
                <SecurityUpdateOutlinedIcon sx={{ mr: 0.5 }} />
                {`${deviceProgressCount} of ${devicesCount}`}
              </Grid>
            </>
          ) : (
            <Grid alignItems="center" display="flex" item>
              No active Phased Rollout
            </Grid>
          )}
          <Grid item>
            <Button onClick={id ? onDelete : onOpen} variant="text">
              {id ? 'Stop Phased Rollout' : 'Start Phased Rollout'}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  },
);
