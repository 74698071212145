import { Box } from '@mui/material';
import type { GridRowParams } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Table } from '@components/Table';
import { useScanDevices } from '@hooks/api';
import { useColumnVisibilityModel } from '@hooks/useColumnVisibilityModel';
import { useTableFilterSort } from '@hooks/useTableFilterSort';
import type { ScanDevice } from 'types/api';

import { EditScanDeviceFooter } from './EditScanDeviceFooter';
import { ResetDevicesButton } from './ResetDevicesButton';
import { ScanDevicesTableSearchFilter } from './SearchFilter';
import { SyncMdmButton } from './SyncMdmButton';
import {
  columns,
  initialTableFilterSortArgs,
  columnVisibilityModelDesktop,
  columnVisibilityModelMobile,
} from './constants';

export interface ScanDevicesTableProps {
  syncWithQueryParams?: boolean;
}

export const ScanDevicesTable: FC<ScanDevicesTableProps> = ({ syncWithQueryParams = false }) => {
  const tableFilterSort = useTableFilterSort(syncWithQueryParams)(initialTableFilterSortArgs);
  const { filters, page, size, selectedRowIds, setFilters, setSelectedRowIds, sort } =
    tableFilterSort;

  const { data, error, isLoading, mutate } = useScanDevices(
    {
      filters,
      page,
      size,
      sort,
    },
    {
      refreshInterval: 60000,
    },
  );

  const hasSelectedRows = selectedRowIds.length > 0;

  const components = useMemo(
    () => ({
      Footer: hasSelectedRows ? EditScanDeviceFooter : undefined,
    }),
    [hasSelectedRows],
  );

  const componentsProps = useMemo(
    () => ({
      footer: hasSelectedRows ? { mutate } : undefined,
    }),
    [hasSelectedRows, mutate],
  );

  const prevRoute = useLocation();
  const navigate = useNavigate();
  const onRowClick = useCallback(
    (rowData: GridRowParams<ScanDevice>) => {
      setSelectedRowIds([]);
      navigate(`/devices/${rowData.row.id}`, {
        state: {
          prevRoute,
        },
      });
    },
    [navigate, setSelectedRowIds],
  );

  const columnVisibilityModel = useColumnVisibilityModel(
    columnVisibilityModelMobile,
    columnVisibilityModelDesktop,
  );

  return (
    <Box
      sx={(theme) => ({
        height: `calc(100vh - ${theme.spacing(30)})`,
        [theme.breakpoints.down('md')]: {
          height: `calc(100vh - ${theme.spacing(34.5)})`,
        },
        [theme.breakpoints.down('sm')]: {
          height: `calc(100vh - ${theme.spacing(40.5)})`,
        },
      })}
    >
      <Box alignItems="center" display="flex" justifyContent="flex-end">
        <SyncMdmButton onSuccess={mutate} />
        <Box sx={{ width: '8px' }} />
        <ResetDevicesButton />
      </Box>
      <Box marginBottom={2} marginTop={1}>
        <ScanDevicesTableSearchFilter initialValues={filters} onChange={setFilters} />
      </Box>
      <Table
        autoPageSize={hasSelectedRows === false}
        checkboxSelection
        columnVisibilityModel={columnVisibilityModel}
        columns={columns}
        components={components}
        componentsProps={componentsProps}
        disableSelectionOnClick
        error={error as Error}
        loading={isLoading}
        localStorageSettingsKey="scan-devices-table-settings"
        onRowClick={onRowClick}
        rowCount={data?.totalElements}
        rows={data?.content}
        tableFilterSort={tableFilterSort}
      />
    </Box>
  );
};
