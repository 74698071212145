import type { FC } from 'react';

import { ControlledCheckbox } from '@components/Form';
import type { ControlledCheckboxProps } from '@components/Form';

import type { DeepScanVersionFormValues } from './DeepScanVersionFormDialog';

export type DeepScanVersionControlledCheckboxProps =
  ControlledCheckboxProps<DeepScanVersionFormValues>;

export const DeepScanVersionControlledCheckbox: FC<DeepScanVersionControlledCheckboxProps> = ({
  controllerProps,
  checkboxProps,
}) => {
  return (
    <ControlledCheckbox
      checkboxProps={{ helperText: ' ', ...checkboxProps }}
      controllerProps={controllerProps}
    />
  );
};
