import type { FC } from 'react';

import { ControlledTextField } from '@components/Form';
import type { ControlledTextFieldProps } from '@components/Form';

import type { SearchFilterFormValues } from './ScanDevicesTableSearchFilter';

export const SearchFilterControlledTextField: FC<
  ControlledTextFieldProps<SearchFilterFormValues>
> = ({ textFieldProps, ...props }) => {
  return <ControlledTextField {...props} textFieldProps={{ ...textFieldProps, size: 'small' }} />;
};
