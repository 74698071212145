import BackIcon from '@mui/icons-material/ArrowBack';
import { Button, type ButtonProps } from '@mui/material';
import type { ReactNode, FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface NavigationState {
  prevRoute?: Location;
}

export interface BackButtonProps extends ButtonProps<'button'> {
  children?: ReactNode;
  defaultRoute: string;
}

export const BackButton: FC<BackButtonProps> = ({ children, defaultRoute, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { prevRoute } = (location?.state as NavigationState) || {};

  const url = prevRoute ? `${prevRoute.pathname}${prevRoute.search}` : defaultRoute;

  return (
    <Button
      {...rest}
      onClick={() => navigate(url)}
      startIcon={<BackIcon />}
      sx={{ whiteSpace: 'nowrap' }}
    >
      {children}
    </Button>
  );
};
