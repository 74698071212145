import type { FC } from 'react';

import { ControlledTextField } from '@components/Form';
import type { ControlledTextFieldProps } from '@components/Form';

import type { PhasedRolloutFormValues } from './PhasedRolloutFormDialog';

export type PhasedRolloutControlledTextFieldProps =
  ControlledTextFieldProps<PhasedRolloutFormValues>;

export const PhasedRolloutControlledTextField: FC<PhasedRolloutControlledTextFieldProps> = ({
  controllerProps,
  textFieldProps,
}) => {
  return (
    <ControlledTextField
      controllerProps={{
        rules: { required: 'This field is required' },
        ...controllerProps,
      }}
      textFieldProps={{ helperText: ' ', required: true, ...textFieldProps }}
    />
  );
};
