import { useMemo } from 'react';

import type { ControlledSelectOption } from '@components/Form';
import { dropdownFilterSortProps } from '@components/Form/ControlledSelect';

import { useVrsTypes } from './api';

export const useVrsTypeSelect = (
  buildOptionsFunc?: (options?: ControlledSelectOption[]) => ControlledSelectOption[],
) => {
  const { data: vrsTypes, isLoading } = useVrsTypes({
    ...dropdownFilterSortProps,
    sort: { field: 'name', sort: 'asc' },
  });

  return useMemo(() => {
    const options = vrsTypes?.content ?? [];

    return {
      options: buildOptionsFunc ? buildOptionsFunc(options) : options,
      isLoading,
    };
  }, [vrsTypes]);
};
