import type { FC } from 'react';

import { useVrsType, useVrsTypeDelete, useVrsTypeUpsert } from '@hooks/api';
import { useFormDialog } from '@hooks/useFormDialog';
import type { VrsType, VrsTypeUpsertInput } from 'types/api';

import { VrsTypeFormDialog } from './VrsTypeFormDialog';
import type { VrsTypeFormValues } from './VrsTypeFormDialog';

export interface VrsFormDialogContainerProps {
  onClose: () => void;
  vrsTypeId?: string;
}

export const VrsTypeFormDialogContainer: FC<VrsFormDialogContainerProps> = ({
  onClose,
  vrsTypeId,
}) => {
  const { trigger: triggerUpsert } = useVrsTypeUpsert(vrsTypeId);
  const { trigger: triggerDelete } = useVrsTypeDelete(vrsTypeId);
  const {
    data: vrsType,
    error: fetchError,
    isLoading,
  } = useVrsType(vrsTypeId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const {
    formError,
    handleClose,
    handleOpen,
    newEntity: newVrsType,
    handleDelete,
    handleSubmit,
    open,
  } = useFormDialog<VrsType, VrsTypeFormValues, VrsTypeUpsertInput>({
    deleteCb: triggerDelete,
    onClose,
    upsertCb: triggerUpsert,
    entityId: vrsTypeId,
  });

  const error = formError || (fetchError as Error);

  return (
    <VrsTypeFormDialog
      error={error}
      isLoadingVrs={isLoading}
      isOpen={open}
      key={vrsType?.id}
      onClose={handleClose}
      onDelete={handleDelete}
      onOpen={handleOpen}
      onSubmit={handleSubmit}
      vrsType={vrsType || newVrsType}
    />
  );
};
