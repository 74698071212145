import { Box, Divider, Grid, Typography } from '@mui/material';
import type { FC, ImgHTMLAttributes, ReactNode } from 'react';

export interface SectionProps {
  children?: ReactNode;
  hideDividerBottom?: boolean;
  imageProps?: ImgHTMLAttributes<HTMLImageElement>;
  title: string;
}

export const Section: FC<SectionProps> = ({ children, hideDividerBottom, imageProps, title }) => {
  return (
    <div>
      <Grid alignItems="center" container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid alignItems="center" container direction="row" item spacing={1}>
          <Grid item md={3} sx={{ display: { xs: 'none', md: 'flex' } }} xs={0}>
            {imageProps ? (
              <Box alignItems="center" my={2} width={imageProps?.width}>
                <img alt={imageProps.alt as string} {...imageProps} />
              </Box>
            ) : null}
          </Grid>
          <Grid container flex={1} item spacing={{ xs: 2, sm: 1 }}>
            {children}
          </Grid>
          <Grid item xs={12}>
            <Divider
              sx={{ display: hideDividerBottom ? 'none' : undefined, my: { xs: 2, md: 4 } }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
