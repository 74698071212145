import { Theme } from '@mui/material';

const modalWidth = 400;
const modalBorder = 1;
const modalPadding = 16;

export const ModalStyle = (theme: Theme) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#2d2d2d',
  position: 'absolute' as const,
  borderRadius: '4px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: `${modalWidth}px`,
  border: `${modalBorder}px solid`,
  boxShadow: '24px',
  padding: `${modalPadding}px`,
});

export const DragNDropAreaStyle = {
  alignItems: 'center',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: `${modalWidth - 2 * modalBorder - 2 * modalPadding}px`,
  border: '1px dashed',
  cursor: 'pointer',
};

export const PreviewAreaStyle = (theme: Theme) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  padding: '8px',
  maxHeight: '200px',
  overflow: 'auto',
});

export const ResetButtonAreaStyle = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
};
