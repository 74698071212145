import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';

import type { VrsAccount } from 'types/api';

import { VrsAccountControlledPasswordField } from './VrsAccountControlledPasswordField';
import { VrsAccountControlledTextField } from './VrsAccountControlledTextField';
import { VrsAccountControlledTypeSelect } from './VrsAccountControlledTypeSelect';
import * as classes from './VrsAccountFormDialog.module.css';

export type VrsAccountFormValues = Omit<VrsAccount, 'id' | 'type'> & { type: string };

export interface VrsAccountFormDialogProps {
  error?: Error;
  onClose: () => void;
  onDelete: () => void;
  onOpen: () => void;
  onSubmit: (values: VrsAccountFormValues) => void;
  isLoadingVrsAccount?: boolean;
  isOpen: boolean;
  vrsAccount: Partial<VrsAccount>;
  vrsAccountFetchError?: Error;
}

const initialValues: VrsAccountFormValues = {
  description: '',
  password: '',
  type: '',
  username: '',
};

export const VrsAccountFormDialog: FC<VrsAccountFormDialogProps> = ({
  error,
  isOpen,
  isLoadingVrsAccount,
  onClose,
  onDelete,
  onOpen,
  onSubmit,
  vrsAccountFetchError,
  vrsAccount,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<VrsAccountFormValues>({
    defaultValues: { ...initialValues, ...vrsAccount, type: vrsAccount.type?.id },
  });

  useEffect(() => {
    reset({ ...initialValues, ...vrsAccount, type: vrsAccount.type?.id });
  }, [vrsAccount]);

  const isEditing = vrsAccount.id !== undefined;
  const isLoading = isSubmitting || isLoadingVrsAccount;
  const displayError = (error as Error) || vrsAccountFetchError;

  return (
    <div>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button onClick={onOpen} variant="outlined">
            Create Account
          </Button>
        </Grid>
      </Grid>
      <Dialog disableEscapeKeyDown fullScreen={fullScreen} maxWidth="md" open={isOpen}>
        {isLoading && <LinearProgress />}
        <DialogTitle sx={{ display: 'flex' }}>
          <span className={classes.title}>{isEditing ? 'Edit Account' : 'Create Account'}</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {displayError && (
            <Alert severity="error" variant="standard">
              <AlertTitle>{displayError.toString()}</AlertTitle>
            </Alert>
          )}
          <form id="vrs-account-form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container marginTop={0.5} spacing={2}>
              <Grid container item spacing={2}>
                <Grid item md={6} xs={12}>
                  <VrsAccountControlledTypeSelect
                    controllerProps={{
                      control,
                      name: 'type',
                    }}
                    textFieldProps={{
                      disabled: isEditing || isLoading,
                      helperText: isEditing ? 'Cannot be changed any more' : ' ',
                      label: 'Type',
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <VrsAccountControlledTextField
                    controllerProps={{
                      control,
                      name: 'description',
                    }}
                    textFieldProps={{
                      disabled: isLoading,
                      label: 'Description',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item md={6} xs={12}>
                  <VrsAccountControlledTextField
                    controllerProps={{
                      control,
                      name: 'username',
                    }}
                    textFieldProps={{
                      autoComplete: 'new-username',
                      disabled: isEditing || isLoading,
                      helperText: isEditing ? 'Cannot be changed any more' : ' ',
                      label: 'Username',
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <VrsAccountControlledPasswordField
                    controllerProps={{
                      control,
                      name: 'password',
                    }}
                    textFieldProps={{
                      autoComplete: 'new-password',
                      disabled: isLoading,
                      label: 'Password',
                      type: 'password',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: (theme) => (fullScreen ? theme.spacing(3) : undefined) }}>
          {isEditing && (
            <div className={classes.deleteButtonContainer}>
              <Button color="error" disabled={isLoading} onClick={handleSubmit(onDelete)}>
                Delete
              </Button>
            </div>
          )}
          <Button disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isLoading}
            form="vrs-account-form"
            type="submit"
            variant="outlined"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
