import useSWR from 'swr';
import type { FetcherResponse, KeyedMutator, PublicConfiguration } from 'swr/_internal';

import { useEnvironment } from '@hooks/useEnvironment';

import { type JsonFetcherArgs, useJsonFetcher, FetchError } from './useJsonFetcher';

export type SwrConfig<ResponseType, ErrorType = FetchError> = Partial<
  PublicConfiguration<
    ResponseType,
    ErrorType,
    (...args: JsonFetcherArgs) => FetcherResponse<ResponseType>
  >
>;

export type ScanDeviceApiDetailQueryMutate<T> = KeyedMutator<T>;

export const useScanDeviceApiGetQuery = <T>(urlPath?: string, swrConfig?: SwrConfig<T>) => {
  const jsonFetcher = useJsonFetcher<T>();
  const { apiUrl } = useEnvironment();
  const url = urlPath ? `${apiUrl}${urlPath}` : undefined;

  return useSWR(url ? [url] : null, jsonFetcher, swrConfig);
};
