import { useEnvironment } from '@hooks/useEnvironment';
import type { ScanDeviceMdmInfo } from 'types/api';

export const APP_MAJOR_VERSION_INTERNAL_FEATURE_FLAGS = 219;
export const APP_MINOR_VERSION_INTERNAL_FEATURE_FLAGS = 1;

export const useDeviceSupportForInternalFeatureFlags = (scanDeviceMdmInfo?: ScanDeviceMdmInfo) => {
  const { isProd: deviceHasRealProdAppVersion } = useEnvironment();

  if (!deviceHasRealProdAppVersion) return true;

  const appVersionParts = scanDeviceMdmInfo?.deepScanVersion?.split('.');
  const appMajorVersion = Number(appVersionParts?.[0]);
  const appMinorVersion = Number(appVersionParts?.[1] ?? 0);

  if (isNaN(appMajorVersion)) return false;

  if (appMajorVersion === APP_MAJOR_VERSION_INTERNAL_FEATURE_FLAGS) {
    return appMinorVersion >= APP_MINOR_VERSION_INTERNAL_FEATURE_FLAGS;
  }

  return appMajorVersion >= APP_MAJOR_VERSION_INTERNAL_FEATURE_FLAGS;
};
