import type { GridColDef, GridSortDirection } from '@mui/x-data-grid';

import type { TableProps } from '@components/Table';
import type { FilterSortProps } from '@hooks/useTableFilterSort';
import type { VrsAccount } from 'types/api';

export const initialTableFilterSortArgs: Partial<FilterSortProps> = {
  sort: { field: 'description', sort: 'asc' as GridSortDirection },
};

export const columns: GridColDef[] = [
  {
    disableColumnMenu: true,
    field: 'description',
    filterable: false,
    headerName: 'Description',
    hideable: false,
    minWidth: 200,
    sortable: true,
    flex: 1,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'type',
    filterable: false,
    headerName: 'Type',
    sortable: true,
    width: 160,
    type: 'string',
    renderCell: ({ row }: { row: VrsAccount }) => row.type.name,
  },
  {
    disableColumnMenu: true,
    field: 'username',
    filterable: false,
    headerName: 'Username',
    sortable: true,
    width: 160,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'id',
    filterable: false,
    headerName: 'ID',
    sortable: true,
    width: 300,
    type: 'string',
  },
];

export const columnVisibilityModelDesktop: TableProps['columnVisibilityModel'] = {};

export const columnVisibilityModelMobile: TableProps['columnVisibilityModel'] = {
  id: false,
  username: false,
};
