import type { FC } from 'react';

import { ControlledCheckbox } from '@components/Form';
import type { ControlledCheckboxProps } from '@components/Form';

import type { VrsTypeFormValues } from './VrsTypeFormDialog';

export type VrsTypeControlledCheckboxProps = ControlledCheckboxProps<VrsTypeFormValues>;

export const VrsTypeControlledCheckbox: FC<VrsTypeControlledCheckboxProps> = ({
  controllerProps,
  checkboxProps,
}) => {
  return (
    <ControlledCheckbox
      checkboxProps={{ helperText: ' ', ...checkboxProps }}
      controllerProps={controllerProps}
    />
  );
};
