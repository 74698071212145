import type { FC } from 'react';

import { useMapboxAddress } from '@hooks/api';

export interface LastKnownAddressProps {
  lat?: number | null;
  long?: number | null;
}

export const LastKnownAddress: FC<LastKnownAddressProps> = ({ lat, long }) => {
  const { address } = useMapboxAddress(lat, long);

  if (!address) {
    return null;
  }

  return <>{address}</>;
};
