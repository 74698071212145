import NoteAdd from '@mui/icons-material/NoteAdd';
import { Box, Modal } from '@mui/material';
import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Content } from './Content';
import { ModalStyle, DragNDropAreaStyle } from './styles';

export type Props = {
  open: boolean;
  onClose: () => void;
};

export const ResetDevicesModal = ({ open, onClose }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleClose = () => {
    setSelectedFile(null);
    onClose();
  };

  const onDrop = useCallback(([file]: File[]) => {
    setSelectedFile(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { 'text/plain': ['.txt'] },
  });

  return (
    <Modal onClose={handleClose} open={open}>
      <Box sx={ModalStyle}>
        <Box {...getRootProps()} sx={DragNDropAreaStyle}>
          <input {...getInputProps()} />
          <NoteAdd fontSize="large" />
          <Box sx={{ height: '8px' }} />
          <div>{"Drag'n'drop a file here, or click to select a file"}</div>
          {selectedFile ? <div>{`Currently selected: ${selectedFile.name}`}</div> : null}
        </Box>
        {selectedFile ? <Content file={selectedFile} onResetTriggered={handleClose} /> : null}
      </Box>
    </Modal>
  );
};
