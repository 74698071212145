import useSWRMutation from 'swr/mutation';

import { useEnvironment } from '@hooks/useEnvironment';
import type { ScanDevice } from 'types/api';

import { MutationFetcherKey, useMutationFetcher } from '../useMutationFetcher';

export const useMdmSync = () => {
  const { apiUrl } = useEnvironment();
  const mutationFetcher = useMutationFetcher<ScanDevice[]>();

  return useSWRMutation<ScanDevice[], Error, MutationFetcherKey>(
    [`${apiUrl}/devices/sync`, { method: 'POST' }],
    mutationFetcher,
  );
};
