import { TextField, type TextFieldProps } from '@mui/material';
import { DateTimePicker, type DateTimePickerProps } from '@mui/x-date-pickers';
import type { DateInput, DateTime } from 'luxon';
import type { FC } from 'react';
import { useController, type FieldValues, type UseControllerProps } from 'react-hook-form';

import { FormErrorMessage } from './FormErrorMessage';

export interface ControlledDateTimePickerProps<FormValuesType extends FieldValues> {
  controllerProps: UseControllerProps<FormValuesType>;
  dateTimePickerProps?: Partial<DateTimePickerProps<DateInput, DateTime>>;
  textFieldProps?: TextFieldProps;
}

export type ControlledDateTimePicker<FormValuesType extends FieldValues = FieldValues> = FC<
  ControlledDateTimePickerProps<FormValuesType>
>;

export const ControlledDateTimePicker: ControlledDateTimePicker = ({
  controllerProps,
  dateTimePickerProps,
  textFieldProps,
}) => {
  const {
    field: { value, ...field },
    fieldState: { error },
  } = useController(controllerProps);

  return (
    <DateTimePicker
      {...field}
      inputFormat="dd.MM.yyyy,HH:mm"
      {...dateTimePickerProps}
      label={dateTimePickerProps?.label || textFieldProps?.label}
      onChange={(event) => {
        field.onChange(event);
        dateTimePickerProps?.onChange && dateTimePickerProps.onChange(event);
      }}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          error={error !== undefined}
          helperText={
            error ? (
              <FormErrorMessage error={error} />
            ) : (
              params?.inputProps?.helperText ?? textFieldProps?.helperText
            )
          }
          required={controllerProps.rules?.required !== undefined}
        />
      )}
      value={value}
    />
  );
};
