import useSWRMutation from 'swr/mutation';

import { useEnvironment } from '@hooks/useEnvironment';
import type { PhasedRollout } from 'types/api';

import { MutationFetcherKey, useMutationFetcher } from '../useMutationFetcher';

export const usePhasedRolloutDelete = () => {
  const mutationFetcher = useMutationFetcher<PhasedRollout>();
  const { apiUrl } = useEnvironment();

  return useSWRMutation<PhasedRollout, Error, MutationFetcherKey>(
    [`${apiUrl}/phased-rollouts`, { method: 'DELETE' }],
    mutationFetcher,
  );
};
