import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';

import {
  useVrsAccount,
  useVrsAccountDelete,
  useVrsAccountUpsert,
  useVrsAccountValidation,
} from '@hooks/api';
import { useFormDialog } from '@hooks/useFormDialog';
import type { VrsAccount, VrsAccountUpsertInput } from 'types/api/vrs-accounts';

import { VrsAccountFormDialog } from './VrsAccountFormDialog';
import type { VrsAccountFormValues } from './VrsAccountFormDialog';

export interface VrsAccountFormDialogContainerProps {
  onClose: () => void;
  vrsAccountId?: string;
}

export const VrsAccountFormDialogContainer: FC<VrsAccountFormDialogContainerProps> = ({
  onClose,
  vrsAccountId,
}) => {
  const { trigger: triggerUpsert } = useVrsAccountUpsert(vrsAccountId);
  const { trigger: triggerDelete } = useVrsAccountDelete(vrsAccountId);
  const {
    data: vrsAccount,
    error: fetchError,
    isLoading,
  } = useVrsAccount(vrsAccountId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const validateVrsAccount = useVrsAccountValidation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    formError,
    handleClose,
    handleOpen,
    newEntity: newVrsAccount,
    handleDelete,
    handleSubmit,
    open,
    setFormError,
  } = useFormDialog<VrsAccount, VrsAccountFormValues, VrsAccountUpsertInput>({
    deleteCb: triggerDelete,
    onClose,
    upsertCb: triggerUpsert,
    entityId: vrsAccountId,
  });

  const handleSubmitWithCredentialsCheck: SubmitHandler<VrsAccountFormValues> = useCallback(
    async (data) => {
      try {
        const { message, isValid } = await validateVrsAccount(data);

        // Wrong crdentials
        if (isValid === false) {
          throw new Error(message);
        }

        // Credentials could not be validated
        if (isValid === null) {
          enqueueSnackbar('Account validation could not be done: ' + message, {
            autoHideDuration: 5000,
            variant: 'warning',
          });
        }

        handleSubmit(data);
      } catch (error) {
        setFormError(error as Error);
      }
    },
    [enqueueSnackbar, handleSubmit, setFormError, validateVrsAccount],
  );

  const error = formError || (fetchError as Error);

  return (
    <VrsAccountFormDialog
      error={error}
      isLoadingVrsAccount={isLoading}
      isOpen={open}
      onClose={handleClose}
      onDelete={handleDelete}
      onOpen={handleOpen}
      onSubmit={handleSubmitWithCredentialsCheck}
      vrsAccount={vrsAccount || newVrsAccount}
    />
  );
};
