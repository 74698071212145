export const useEnvironment: () => Environment = () => {
  const {
    DEV,
    MODE,
    VITE_AUTH0_DOMAIN,
    VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_AUDIENCE,
    VITE_AUTH0_SCOPE,
    VITE_ENABLE_PRODUCTION_PROFILING,
    VITE_API_URL,
    VITE_API_URL_V3,
    VITE_DATADOG_URL,
    VITE_MAPBOX_API_TOKEN,
    VITE_GRAFANA_SCANS_DASHBOARD_URL,
  } = import.meta.env;

  const STAGING = MODE === 'test';
  const PROD = import.meta.env?.PROD && !STAGING;

  return {
    auth0Audience: VITE_AUTH0_AUDIENCE,
    auth0ClientId: VITE_AUTH0_CLIENT_ID,
    auth0Domain: VITE_AUTH0_DOMAIN,
    auth0Scope: VITE_AUTH0_SCOPE,
    apiUrl: VITE_API_URL,
    apiUrlV3: VITE_API_URL_V3,
    datadogUrl: VITE_DATADOG_URL,
    enableProductionProfiling: VITE_ENABLE_PRODUCTION_PROFILING === 'true',
    isDev: DEV,
    isProd: PROD,
    isStaging: STAGING,
    mapboxApiToken: VITE_MAPBOX_API_TOKEN,
    grafanaScansDashboardUrl: VITE_GRAFANA_SCANS_DASHBOARD_URL,
  };
};

export interface Environment {
  auth0Audience: string;
  auth0ClientId: string;
  auth0Domain: string;
  auth0Scope: string;
  apiUrl: string;
  apiUrlV3: string;
  datadogUrl: string;
  enableProductionProfiling: boolean;
  isDev: boolean;
  isProd: boolean;
  isStaging: boolean;
  mapboxApiToken: string;
  grafanaScansDashboardUrl: string;
}
