import { useAuth0 } from '@auth0/auth0-react';
import type { Fetcher } from 'swr';
import type { FetcherResponse } from 'swr/_internal';

export type JsonFetcherArgs = [url: string, fetchInit?: RequestInit];

export type JsonFetcher = <ResponseType>() => (
  ...args: JsonFetcherArgs
) => FetcherResponse<ResponseType>;

export class FetchError extends Error {
  status: number;
  statusText: string;

  constructor(message: string, status: number, statusText: string) {
    super(message);
    this.status = status;
    this.statusText = statusText;
  }
}

export const useJsonFetcher = <T>() => {
  const { getAccessTokenSilently } = useAuth0();

  const jsonFetcher: Fetcher<T, JsonFetcherArgs> = async ([url, fetchInit]) => {
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(url, {
      ...fetchInit,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...fetchInit?.headers,
      },
    });

    // If the response is not ok, throw an error
    if (!response.ok) {
      if (response.bodyUsed) {
        // API returns a JSON object with the error message
        const error = await response.json();

        if (error.message) {
          throw new FetchError(error.message, response.status, response.statusText);
        }
      }

      // If the error message is not present, throw a generic error
      throw new FetchError(
        `Something went wrong: ${response.statusText || response.status}`,
        response.status,
        response.statusText,
      );
    }

    // If the response is ok but there is no data sent just return
    if (response.status === 204) {
      return;
    }

    // If the response is no json, return the response as text
    if (response.headers.get('Content-Type') !== 'application/json') {
      return response.text();
    }

    // Happy path, return the data as JSON
    return response.json();
  };

  return jsonFetcher;
};
