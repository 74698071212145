import { useSnackbar } from 'notistack';
import { useRef, useState, useEffect, useCallback } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';

import type { UseMutation } from './api/useMutationFetcher';

export interface useFormDialogArgs<Entity, FormValuesAfterSubmit extends FieldValues> {
  deleteCb?: UseMutation<Entity, Error>['trigger'];
  entityId?: string;
  onClose: () => void;
  upsertCb: UseMutation<Entity, Error, FormValuesAfterSubmit>['trigger'];
}

export const useFormDialog = <
  Entity,
  FormValues extends FieldValues,
  FormValuesAfterSubmit extends FormValues,
>({
  deleteCb,
  entityId,
  onClose,
  upsertCb,
}: useFormDialogArgs<Entity, FormValuesAfterSubmit>) => {
  const newEntityRef = useRef({});
  const [formError, setFormError] = useState<Error>();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  useEffect(() => {
    if (entityId !== undefined) {
      handleOpen();
    }
  }, [handleOpen, entityId]);

  const handleClose = () => {
    setOpen(false);
    newEntityRef.current = {};
    setFormError(undefined);
    onClose();
  };

  const handleSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await upsertCb(data as FormValuesAfterSubmit);
      enqueueSnackbar(entityId ? 'Update successful!' : 'Creation successful!', {
        variant: 'success',
      });
      handleClose();
    } catch (error) {
      setFormError(error as Error);
    }
  };

  const handleDelete = useCallback(async () => {
    if (!deleteCb) {
      return;
    }

    if (!entityId || !confirm('Are you sure you want to delete?')) {
      return;
    }

    try {
      await deleteCb();
      enqueueSnackbar('Deletion successful!', {
        variant: 'success',
      });
      handleClose();
    } catch (error) {
      setFormError(error as Error);
    }
  }, [deleteCb, entityId, enqueueSnackbar, handleClose]);

  return {
    formError,
    newEntity: newEntityRef.current,
    open,
    handleClose,
    handleOpen,
    handleDelete,
    handleSubmit,
    setFormError,
  };
};
