import type { FC } from 'react';

import { ControlledPasswordField, type ControlledPasswordFieldProps } from '@components/Form';

import type { VrsAccountFormValues } from './VrsAccountFormDialog';

export type VrsAccountControlledPasswordFieldProps =
  ControlledPasswordFieldProps<VrsAccountFormValues>;

export const VrsAccountControlledPasswordField: FC<VrsAccountControlledPasswordFieldProps> = ({
  controllerProps,
  textFieldProps,
}) => {
  return (
    <ControlledPasswordField
      controllerProps={{ ...controllerProps, rules: { required: 'This field is required' } }}
      textFieldProps={{ helperText: ' ', ...textFieldProps }}
    />
  );
};
