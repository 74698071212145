import { Button } from '@mui/material';
import type { ButtonProps } from '@mui/material';
import type { FC } from 'react';

export interface ExternalLinkButtonProps extends ButtonProps<'a'> {
  href: string;
}

export const ExternalLinkButton: FC<ExternalLinkButtonProps> = ({ children, ...buttonProps }) => {
  return (
    <Button {...buttonProps} rel="noopener noreferrer" target="_blank">
      {children}
    </Button>
  );
};
