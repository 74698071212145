import { TextField, type TextFieldProps } from '@mui/material';
import type { ReactNode } from 'react';
import { Controller, type FieldValues, type ControllerProps } from 'react-hook-form';

import { FormErrorMessage } from './FormErrorMessage';

export interface ControlledTextFieldProps<FormValuesType extends FieldValues> {
  controllerProps: Omit<ControllerProps<FormValuesType>, 'render'>;
  textFieldProps: TextFieldProps;
  children?: ReactNode;
}

export const ControlledTextField = <FormValuesType extends FieldValues>({
  controllerProps,
  textFieldProps,
  children,
}: ControlledTextFieldProps<FormValuesType>): JSX.Element => (
  <Controller
    {...controllerProps}
    render={({ field, fieldState: { error } }) => (
      <TextField
        error={error !== undefined}
        fullWidth
        required={controllerProps.rules?.required !== undefined}
        {...field}
        {...textFieldProps}
        helperText={error ? <FormErrorMessage error={error} /> : textFieldProps.helperText}
        onChange={(event) => {
          field.onChange(event);
          textFieldProps.onChange && textFieldProps.onChange(event);
        }}
        value={field.value ?? ''}
      >
        {children}
      </TextField>
    )}
  />
);
