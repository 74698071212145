import type { GridCellParams } from '@mui/x-data-grid';

export const getLocalizedDateString = (value?: string, locale = 'de-DE') => {
  if (!value) {
    return '';
  }

  const date = new Date(value);

  return date && !isNaN(date.valueOf())
    ? date.toLocaleString(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    : '';
};

export const renderDate = ({ value }: GridCellParams) => getLocalizedDateString(value);
