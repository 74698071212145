import type { FC } from 'react';

import { ControlledCheckbox } from '@components/Form';
import type { ControlledCheckboxProps } from '@components/Form';

import type { HardwareVersionFormValues } from './HardwareVersionFormDialog';

export type HardwareVersionControlledCheckboxProps =
  ControlledCheckboxProps<HardwareVersionFormValues>;

export const HardwareVersionControlledCheckbox: FC<HardwareVersionControlledCheckboxProps> = ({
  controllerProps,
  checkboxProps,
}) => {
  return (
    <ControlledCheckbox
      checkboxProps={{ helperText: ' ', ...checkboxProps }}
      controllerProps={controllerProps}
    />
  );
};
