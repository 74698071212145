import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Button, IconButton, Menu, type ButtonProps } from '@mui/material';
import * as React from 'react';

type HandleClose = () => void;

export interface MenuButtonProps {
  buttonType?: 'button' | 'icon';
  buttonProps?: ButtonProps<'button'>;
  children: (handleClose: HandleClose) => React.ReactNode;
  id: string;
  label: string;
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  buttonProps: { startIcon, ...buttonProps } = {},
  buttonType = 'button',
  children,
  id,
  label,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose: HandleClose = () => {
    setAnchorEl(null);
  };

  const defaultButtonProps = {
    'aria-controls': open ? id : undefined,
    'aria-haspopup': true,
    'aria-expanded': open ? true : undefined,
    id,
    onClick: handleClick,
    size: 'small' as const,
  };

  return (
    <div>
      {buttonType === 'button' ? (
        <Button
          {...defaultButtonProps}
          endIcon={<ArrowDropDownOutlinedIcon />}
          startIcon={startIcon}
          variant="outlined"
          {...buttonProps}
        >
          {label}
        </Button>
      ) : (
        <IconButton {...defaultButtonProps} {...buttonProps}>
          {startIcon}
        </IconButton>
      )}
      <Menu
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        id={`${id}-menu`}
        onClose={handleClose}
        open={open}
      >
        {children(handleClose)}
      </Menu>
    </div>
  );
};
