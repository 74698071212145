import { Box, LinearProgress, type LinearProgressProps, Typography } from '@mui/material';
import type { FC } from 'react';

export interface StorageIndicatorProps {
  available?: number;
  total?: number;
}

const getColor = (value: number): LinearProgressProps['color'] => {
  if (value >= 90) {
    return 'error';
  }

  if (value >= 75) {
    return 'warning';
  }

  return 'primary';
};

const UNIT = 'GB';

export const getUsageText = (total: number, used?: number, percentage?: number): string => {
  if (total && !used) {
    return `${total} ${UNIT}`;
  }

  if (!percentage) {
    return `${used} / ${total} ${UNIT}`;
  }

  return `${used} / ${total} ${UNIT} (${percentage}%)`;
};

export const StorageIndicator: FC<StorageIndicatorProps> = ({ available, total }) => {
  if (total && !available) {
    return <>{getUsageText(total)}</>;
  }

  if (!available || !total) {
    return null;
  }

  const used = Math.round(total - available);
  const percentage = Math.round((used / total) * 100);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        width: (theme) => theme.spacing(24),
      }}
    >
      <Box sx={{ display: 'flex', position: 'absolute', top: 0, width: '100%', zIndex: 1 }}>
        <Typography align="center" width="100%">
          {getUsageText(total, used, percentage)}
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          color={getColor(percentage)}
          sx={{ height: (theme) => theme.spacing(3) }}
          value={percentage}
          variant="determinate"
        />
      </Box>
    </Box>
  );
};
