import type { ThemeOptions } from '@mui/material/styles';

export const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#00AB84',
      light: 'rgb(51, 187, 156)',
      dark: 'rgb(0, 119, 92)',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#373737',
    },
    background: {
      default: '#f7f8f8',
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiAccordion: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
};
