import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  Button,
  Dialog,
  LinearProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  Alert,
  AlertTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import type { VrsType } from 'types/api';

import { VrsTypeControlledCheckbox } from './VrsTypeCheckbox';
import { VrsTypeControlledTextField } from './VrsTypeControlledTextField';
import * as classes from './VrsTypeFormDialog.module.css';

export type VrsTypeFormValues = Omit<VrsType, 'id'>;

const initialValues: VrsTypeFormValues = {
  isAlertingEnabled: false,
  isDefault: false,
  name: '',
  mountpoint: '',
  serverAddress: '',
  serverPort: 2101,
};

export interface VrsTypeFormDialogProps {
  error?: Error;
  onClose: () => void;
  onDelete: () => void;
  onOpen: () => void;
  onSubmit: (values: VrsTypeFormValues) => void;
  isLoadingVrs?: boolean;
  isOpen: boolean;
  vrsType: Partial<VrsType>;
  vrsTypeFetchError?: Error;
}

export const VrsTypeFormDialog: React.FC<VrsTypeFormDialogProps> = ({
  error,
  isOpen,
  isLoadingVrs,
  onClose,
  onDelete,
  onOpen,
  onSubmit,
  vrsTypeFetchError,
  vrsType,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<VrsTypeFormValues>({
    defaultValues: { ...initialValues, ...vrsType },
  });

  React.useEffect(() => {
    reset({ ...initialValues, ...vrsType });
  }, [vrsType]);

  const isEditing = vrsType.id !== undefined;
  const isLoading = isSubmitting || isLoadingVrs;
  const displayError = (error as Error) || vrsTypeFetchError;

  return (
    <div>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button onClick={onOpen} variant="outlined">
            Create VRS Type
          </Button>
        </Grid>
      </Grid>
      <Dialog disableEscapeKeyDown fullScreen={fullScreen} maxWidth="md" open={isOpen}>
        {isLoading && <LinearProgress />}
        <DialogTitle sx={{ display: 'flex' }}>
          <span className={classes.title}>{isEditing ? 'Edit VRS Type' : 'Create VRS Type'}</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {displayError && (
            <Alert severity="error" variant="standard">
              <AlertTitle>{displayError.toString()}</AlertTitle>
            </Alert>
          )}
          <form id="vrs-type-form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container marginTop={0.5} spacing={2}>
              <Grid item md={6} xs={12}>
                <VrsTypeControlledTextField
                  controllerProps={{
                    control,
                    name: 'name',
                  }}
                  textFieldProps={{
                    disabled: isLoading,
                    label: 'Name',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <VrsTypeControlledTextField
                  controllerProps={{
                    control,
                    name: 'serverAddress',
                  }}
                  textFieldProps={{
                    autoComplete: 'new-server-address',
                    disabled: isLoading,
                    label: 'Server Address',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <VrsTypeControlledTextField
                  controllerProps={{
                    control,
                    name: 'serverPort',
                  }}
                  textFieldProps={{
                    disabled: isLoading,
                    label: 'Server Port',
                    type: 'number',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <VrsTypeControlledTextField
                  controllerProps={{
                    control,
                    name: 'mountpoint',
                  }}
                  textFieldProps={{
                    disabled: isLoading,
                    label: 'Mount Point',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <VrsTypeControlledCheckbox
                  checkboxProps={{
                    disabled: isLoading,
                    helperText:
                      'Set this VRS Type as the default for all new devices. Please make sure that there are enough VRS Accounts of this VRS Type available.',
                    label: 'Default VRS Type for all new devices',
                  }}
                  controllerProps={{
                    control,
                    name: 'isDefault',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <VrsTypeControlledCheckbox
                  checkboxProps={{
                    disabled: isLoading,
                    label: 'Enable Alerting',
                  }}
                  controllerProps={{
                    control,
                    name: 'isAlertingEnabled',
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: (theme) => (fullScreen ? theme.spacing(3) : undefined) }}>
          {isEditing && (
            <div className={classes.deleteButtonContainer}>
              <Button color="error" disabled={isLoading} onClick={handleSubmit(onDelete)}>
                Delete
              </Button>
            </div>
          )}
          <Button disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isLoading}
            form="vrs-type-form"
            type="submit"
            variant="outlined"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
