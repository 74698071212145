import type { GridRowParams } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import type { FC } from 'react';

import { HardwareVersionFormDialogContainer } from '@components/HardwareVersionForm';
import { Table } from '@components/Table';
import type { TableProps } from '@components/Table';
import { useHardwareVersions } from '@hooks/api';
import { useColumnVisibilityModel } from '@hooks/useColumnVisibilityModel';
import { useTableFilterSort } from '@hooks/useTableFilterSort';
import type { HardwareVersion } from 'types/api';

import * as classes from './HardwareVersionsTable.module.css';
import {
  columns,
  initialTableFilterSortArgs,
  columnVisibilityModelDesktop,
  columnVisibilityModelMobile,
} from './constants';

export interface HardwareVersionsTableProps {
  syncWithQueryParams?: boolean;
}

export const HardwareVersionsTable: FC<HardwareVersionsTableProps> = ({
  syncWithQueryParams = false,
}) => {
  const [hardwareVersionId, sethardwareVersionId] = useState<string | undefined>(undefined);
  const tableFilterSort = useTableFilterSort(syncWithQueryParams)(initialTableFilterSortArgs);
  const { filters, page, size, sort } = tableFilterSort;
  const { data, error, isLoading, mutate } = useHardwareVersions(
    {
      filters,
      page,
      size,
      sort,
    },
    {
      refreshInterval: 60000,
    },
  );
  const columnVisibilityModel = useColumnVisibilityModel(
    columnVisibilityModelMobile,
    columnVisibilityModelDesktop,
  );

  const onRowClick: TableProps['onRowClick'] = useCallback(
    (rowData: GridRowParams<HardwareVersion>) => {
      sethardwareVersionId(rowData.row.id);
    },
    [sethardwareVersionId],
  );

  const onClose = useCallback(() => {
    sethardwareVersionId(undefined);
    mutate();
  }, [sethardwareVersionId, mutate]);

  return (
    <div className={classes.container}>
      <HardwareVersionFormDialogContainer hardwareVersionId={hardwareVersionId} onClose={onClose} />
      <Table
        columnVisibilityModel={columnVisibilityModel}
        columns={columns}
        disableSelectionOnClick
        error={error as Error}
        loading={isLoading}
        localStorageSettingsKey="hardware-versions-table-settings"
        onRowClick={onRowClick}
        rowCount={data?.totalElements}
        rows={data?.content}
        tableFilterSort={tableFilterSort}
      />
    </div>
  );
};
