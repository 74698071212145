import type { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Layout } from '@components/Layout';
import { DeepScanVersionsListPage } from '@pages/DeepScanVersions';
import { HardwareVersionsListPage } from '@pages/HardwareVersions';
import { ScanDevicesListPage, ScanDeviceDetailPage } from '@pages/ScanDevices';
import { VrsAccountsListPage } from '@pages/VrsAccounts';
import { VrsTypesListPage } from '@pages/VrsTypes';

export const Router: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Layout />}>
        <Route element={<ScanDevicesListPage />} path="/devices" />
        <Route element={<ScanDeviceDetailPage />} path="/devices/:id" />
        <Route element={<VrsAccountsListPage />} path="/vrs-accounts" />
        <Route element={<VrsTypesListPage />} path="/vrs-types" />
        <Route element={<HardwareVersionsListPage />} path="/hardware-versions" />
        <Route element={<DeepScanVersionsListPage />} path="/deepscan-versions" />
        <Route element={<Navigate replace to="/devices" />} path="*" />
      </Route>
    </Routes>
  </BrowserRouter>
);
