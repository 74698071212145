import type { VrsAccountFormValues } from '@components/VrsAccountForm';
import { useEnvironment } from '@hooks/useEnvironment';
import type { VrsAccountValidationResponse } from 'types/api';

import { useJsonFetcher } from '../useJsonFetcher';

export const useVrsAccountValidation = () => {
  const jsonFetcher = useJsonFetcher<VrsAccountValidationResponse>();
  const { apiUrl } = useEnvironment();

  const validateVrsAccount = (vrsAccount: VrsAccountFormValues) => {
    return jsonFetcher([
      `${apiUrl}/${'vrs-accounts'}/validate`,
      {
        method: 'PUT',
        body: JSON.stringify(vrsAccount),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ]);
  };

  return validateVrsAccount;
};
