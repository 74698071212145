import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';

import { useMdmSync } from '@hooks/api';

export interface SyncMdmButtonProps {
  onSuccess: () => void;
}

export const SyncMdmButton: FC<SyncMdmButtonProps> = ({ onSuccess }) => {
  const { isMutating, trigger } = useMdmSync();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    try {
      const results = await trigger();

      enqueueSnackbar(`Sync Devices with MDM successful - ${results?.length} device(s) synced`, {
        variant: 'success',
      });
      onSuccess();
    } catch (error) {
      enqueueSnackbar((error as Error).toString(), { variant: 'error' });
    }
  };

  return (
    <LoadingButton
      color="primary"
      loading={isMutating}
      onClick={handleClick}
      size="small"
      startIcon={<RefreshIcon />}
      variant="text"
    >
      Sync MDM
    </LoadingButton>
  );
};
