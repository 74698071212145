import { AppBar as MuiAppBar, Grid, Paper, Toolbar, Typography } from '@mui/material';
import { useEffect } from 'react';
import type { FC } from 'react';

import { AuthMenu } from '@components/Auth';
import { ThemeSwitcher } from '@components/Theme';
import { Link } from '@components/Util';
import { useEnvironment } from '@hooks/useEnvironment';

import { AppBarNavigation } from './AppBarNavigation';

export const STAGING_NOTICE_TEXT = 'Test';
export const APP_TITLE = 'DeviceHub';

export const AppBar: FC = () => {
  const { isStaging } = useEnvironment();

  useEffect(() => {
    document.title = `${APP_TITLE} ${isStaging ? STAGING_NOTICE_TEXT : ''}`;
  }, [isStaging]);

  return (
    <>
      <MuiAppBar color="transparent" elevation={0} position="fixed">
        <Toolbar>
          <Grid alignItems="center" container direction="row" spacing={1}>
            <Grid flexGrow={1} item>
              <Grid alignItems="center" container direction="row" spacing={2}>
                <Grid item>
                  <Link
                    color="inherit"
                    fontWeight="semibold"
                    noWrap
                    position="relative"
                    to="/"
                    underline="none"
                  >
                    {APP_TITLE}
                    {isStaging && (
                      <Typography marginLeft={0.5} position="absolute" top={-4} variant="caption">
                        {STAGING_NOTICE_TEXT}
                      </Typography>
                    )}
                  </Link>
                </Grid>
                <Grid
                  alignContent="center"
                  item
                  justifyContent="center"
                  sx={{
                    margin: {
                      md: 'auto',
                      xs: '0 0 0 auto',
                    },
                  }}
                >
                  <Paper
                    sx={(theme) => ({
                      padding: {
                        md: theme.spacing(1, 3),
                      },
                      overflow: 'hidden',
                      border:
                        theme.palette.mode === 'light'
                          ? '1px solid ' + theme.palette.divider
                          : undefined,
                    })}
                  >
                    <AppBarNavigation />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                alignItems="center"
                container
                direction="row"
                justifyContent="center"
                spacing={{ xs: 0, sm: 1 }}
              >
                <Grid item>
                  <ThemeSwitcher />
                </Grid>
                <Grid item>
                  <AuthMenu />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </MuiAppBar>
      <Toolbar />
    </>
  );
};
