import { type AppState, Auth0Provider } from '@auth0/auth0-react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import { StrictMode } from 'react';

import { Auth } from '@components/Auth';
import { Router } from '@components/Router';
import { ColorModeContext } from '@contexts/ColorModeContext';
import { useEnvironment } from '@hooks/useEnvironment';
import { useSetupAppTheme } from '@hooks/useSetupAppTheme';

const onRedirectCallback = ({ returnTo }: AppState = {}) => {
  if (!returnTo) return;

  window.history.replaceState({}, '', returnTo);
};

export const App: FC = () => {
  const { colorMode, theme } = useSetupAppTheme();
  const { auth0Audience, auth0ClientId, auth0Domain, auth0Scope } = useEnvironment();

  return (
    <StrictMode>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Auth0Provider
            audience={auth0Audience}
            clientId={auth0ClientId}
            domain={auth0Domain}
            onRedirectCallback={onRedirectCallback}
            redirectUri={window.location.origin}
            scope={auth0Scope}
          >
            <CssBaseline />
            <Auth>
              <LocalizationProvider adapterLocale="de" dateAdapter={AdapterLuxon}>
                <SnackbarProvider maxSnack={2}>
                  <Router />
                </SnackbarProvider>
              </LocalizationProvider>
            </Auth>
          </Auth0Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </StrictMode>
  );
};

export default App;
