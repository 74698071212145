import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  Button,
  Dialog,
  LinearProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  Alert,
  AlertTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import type { PhasedRollout } from 'types/api';

import { PhasedRolloutControl } from './PhasedRolloutControl';
import { PhasedRolloutControlledDateTimePicker } from './PhasedRolloutControlledDateTimePicker';
import { PhasedRolloutControlledTextField } from './PhasedRolloutControlledTextField';
import * as classes from './PhasedRolloutFormDialog.module.css';

export type PhasedRolloutFormValuesAfterSubmit = Partial<
  Pick<PhasedRollout, 'deviceUpdatePercentage' | 'fullRolloutAfter'>
>;
export type PhasedRolloutFormValues = PhasedRolloutFormValuesAfterSubmit;

const initialValues: PhasedRolloutFormValues = {
  deviceUpdatePercentage: 10,
  fullRolloutAfter: '',
};

export interface PhasedRolloutFormDialogProps {
  error?: Error;
  onClose: () => void;
  onDelete: () => void;
  onOpen: () => void;
  onSubmit: (values: PhasedRolloutFormValues) => void;
  isLoadingActivePhasedRollout?: boolean;
  isOpen: boolean;
  phasedRollout: Partial<PhasedRollout>;
}

export const PhasedRolloutFormDialog: React.FC<PhasedRolloutFormDialogProps> = ({
  error,
  isOpen,
  isLoadingActivePhasedRollout,
  onClose,
  onDelete,
  onOpen,
  onSubmit,
  phasedRollout,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<PhasedRolloutFormValues>({
    defaultValues: { ...initialValues, ...phasedRollout },
    reValidateMode: 'onSubmit',
    mode: 'onBlur',
  });

  React.useEffect(() => {
    reset({ ...initialValues, ...phasedRollout });
  }, [phasedRollout, reset]);

  const isLoading = isSubmitting || isLoadingActivePhasedRollout;
  const isInputDisabled = isLoading;

  return (
    <>
      <PhasedRolloutControl onDelete={onDelete} onOpen={onOpen} phasedRollout={phasedRollout} />
      <Dialog disableEscapeKeyDown fullScreen={fullScreen} fullWidth maxWidth="md" open={isOpen}>
        {isLoading && <LinearProgress />}
        <DialogTitle sx={{ display: 'flex' }}>
          <span className={classes.title}>Start Phased Rollout</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" variant="standard">
              <AlertTitle>{error.toString()}</AlertTitle>
            </Alert>
          )}
          <form id="vrs-type-form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container marginTop={0.5} spacing={2}>
              <Grid item md={6} xs={12}>
                <PhasedRolloutControlledTextField
                  controllerProps={{
                    control,
                    name: 'deviceUpdatePercentage',
                    rules: {
                      required: 'Device Update Percentage is required',
                      min: {
                        value: 1,
                        message: 'Device Update Percentage invalid',
                      },
                      max: {
                        value: 100,
                        message: 'Device Update Percentage invalid',
                      },
                    },
                  }}
                  textFieldProps={{
                    disabled: isInputDisabled,
                    label: 'Device Update Percentage',
                    type: 'number',
                    inputProps: {
                      min: 1,
                      max: 100,
                    },
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <PhasedRolloutControlledDateTimePicker
                  controllerProps={{
                    control,
                    name: 'fullRolloutAfter',
                  }}
                  dateTimePickerProps={{
                    minDate: DateTime.now(),
                  }}
                  textFieldProps={{
                    disabled: isInputDisabled,
                    label: 'Full Rollout After',
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: (theme) => (fullScreen ? theme.spacing(3) : undefined) }}>
          <Button disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isLoading}
            form="vrs-type-form"
            type="submit"
            variant="outlined"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
