import { Grid } from '@mui/material';
import type { FC, ReactNode } from 'react';

export interface SectionRowProps {
  children?: ReactNode;
  label: string;
}

export const SectionRow: FC<SectionRowProps> = ({ children, label }) => {
  return (
    <Grid
      columnSpacing={{ xs: 1, sm: 4 }}
      container
      item
      justifyContent="center"
      rowSpacing={{ xs: 1 }}
    >
      <Grid
        flex={1}
        item
        sx={(theme) => ({
          fontWeight: {
            sm: theme.typography.fontWeightRegular,
            xs: theme.typography.fontWeightBold,
          },
          textAlign: { xs: 'start', sm: 'end' },
        })}
      >
        {label}:
      </Grid>
      <Grid item sm={8} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
