import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import type { FC, ReactElement } from 'react';

export interface InPlaceEditProps {
  children: ReactElement;
  disabled?: boolean;
  isEditing: boolean;
  isUpdating: boolean;
  selectedValueText: string;
  setIsEditing: (isEditing: boolean) => void;
}

export const InPlaceEdit: FC<InPlaceEditProps> = ({
  disabled,
  children,
  isEditing,
  isUpdating,
  selectedValueText,
  setIsEditing,
}) => {
  return !isEditing ? (
    <Box alignItems="center" display="flex">
      <Typography component="span" marginRight={1}>
        {selectedValueText}
      </Typography>
      <Button disabled={disabled} onClick={() => setIsEditing(true)} size="small">
        change
      </Button>
    </Box>
  ) : (
    <Stack alignItems="center" direction="row" maxWidth={(theme) => theme.spacing(32)} spacing={1}>
      {children}
      <LoadingButton
        aria-label="cancel"
        loading={isUpdating}
        onClick={() => setIsEditing(false)}
        size="small"
      >
        cancel
      </LoadingButton>
    </Stack>
  );
};
