import { MenuItem, type TextFieldProps } from '@mui/material';
import type { ControllerProps, FieldValues } from 'react-hook-form';

import type { FilterSortProps } from '@hooks/useTableFilterSort';

import { ControlledTextField } from './ControlledTextField';

export interface ControlledSelectOption {
  id: string;
  name: string;
}

export const pleaseSelectOption: ControlledSelectOption = { id: '0', name: 'Please Select' };
export const allSelectOption: ControlledSelectOption = { id: '0', name: 'ALL' };

export const dropdownFilterSortProps: Partial<FilterSortProps> = { size: 100 };

const addDefaultOption = (option: ControlledSelectOption) => (options?: ControlledSelectOption[]) =>
  [option, ...(options ?? [])];

export const addPleaseSelectOption = addDefaultOption(pleaseSelectOption);
export const addAllSelectOption = addDefaultOption(allSelectOption);
export interface ControlledSelectProps<FormValuesType extends FieldValues> {
  controllerProps: Omit<ControllerProps<FormValuesType>, 'render'>;
  options: { id: string; name: string }[];
  textFieldProps: TextFieldProps;
}

export const ControlledSelect = <FormValuesType extends FieldValues>({
  controllerProps,
  options,
  textFieldProps,
}: ControlledSelectProps<FormValuesType>): JSX.Element => (
  <ControlledTextField
    controllerProps={controllerProps}
    textFieldProps={{
      ...textFieldProps,
      select: true,
    }}
  >
    {options.map((option) => (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    ))}
  </ControlledTextField>
);
