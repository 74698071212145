import { useAuth0 } from '@auth0/auth0-react';
import { Alert, AlertTitle, Button } from '@mui/material';
import type { FC, ReactNode } from 'react';

import { CenteredLoading } from '@components/Util';
import { useEnvironment } from '@hooks/useEnvironment';

import * as classes from './Auth.module.css';

export const Auth: FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated, isLoading, error, loginWithRedirect, logout } = useAuth0();
  const { isDev } = useEnvironment();

  if (isLoading) {
    return <CenteredLoading />;
  }
  if (!isAuthenticated && error) {
    return (
      <Alert
        action={
          <Button onClick={() => logout()} variant="text">
            Log Out
          </Button>
        }
        className={classes.alert}
        severity="error"
        variant="outlined"
      >
        <AlertTitle>Error: {error.message}</AlertTitle>
        <div>Please logout and make sure to have the correct permission set and try again.</div>
      </Alert>
    );
  }
  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.href } }).catch(
      (e) => isDev && console.error(e),
    );

    return <CenteredLoading />;
  }

  return <div>{children}</div>;
};
