import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  Grid,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { toLocaleString } from '@components/Util/toLocaleString';
import { ScanDeviceDetail } from 'types/api';
import { ResetProcess } from 'types/api/resetProcess';

const ResetProcessesTable = ({ resetProcesses }: { resetProcesses: ResetProcess[] }) => (
  <Table size="small" sx={{ maxWidth: '500px' }}>
    <TableHead>
      <TableRow>
        <TableCell>State</TableCell>
        <TableCell>Requested At</TableCell>
        <TableCell>Finished At</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {resetProcesses.map(({ id, state, requestedAt, finishedAt }) => (
        <TableRow key={id}>
          <TableCell>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {state === 'PENDING' ? <CachedRoundedIcon /> : <CheckCircleOutline />}
              <Box sx={{ width: '8px' }} />
              {state === 'PENDING' ? 'Pending' : 'Done'}
            </Box>
          </TableCell>
          <TableCell>{toLocaleString(requestedAt)}</TableCell>
          <TableCell>{finishedAt ? toLocaleString(finishedAt) : null}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export type Props = {
  scanDeviceDetail: ScanDeviceDetail;
};

export const ResetProcessesSection = ({ scanDeviceDetail: { resetProcesses } }: Props) => (
  <Grid alignItems="center" container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h5">{'Reset Processes'}</Typography>
    </Grid>
    <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={12}>
      {resetProcesses.length > 0 ? (
        <ResetProcessesTable resetProcesses={resetProcesses} />
      ) : (
        'This device has not yet been reset.'
      )}
    </Grid>
  </Grid>
);
