import type { FC } from 'react';
import { useCallback } from 'react';

import { useDeepScanVersion, useDeepScanVersionUpsert } from '@hooks/api';
import { useFormDialog } from '@hooks/useFormDialog';
import type { DeepScanVersion } from 'types/api';

import {
  DeepScanVersionFormDialog,
  DeepScanVersionFormValuesAfterSubmit,
} from './DeepScanVersionFormDialog';
import type { DeepScanVersionFormValues } from './DeepScanVersionFormDialog';

export interface DeepScanVersionFormDialogContainerProps {
  onClose: () => void;
  deepScanVersionId?: string;
}

export const DeepScanVersionFormDialogContainer: FC<DeepScanVersionFormDialogContainerProps> = ({
  onClose,
  deepScanVersionId,
}) => {
  const { trigger: triggerUpsert } = useDeepScanVersionUpsert(deepScanVersionId);
  const {
    data: deepScanVersion,
    error: fetchError,
    isLoading,
  } = useDeepScanVersion(deepScanVersionId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const {
    formError,
    handleClose,
    handleOpen,
    newEntity: newDeepScanVersion,
    handleSubmit,
    open,
  } = useFormDialog<
    DeepScanVersion,
    DeepScanVersionFormValues,
    DeepScanVersionFormValuesAfterSubmit
  >({
    onClose,
    upsertCb: triggerUpsert,
    entityId: deepScanVersionId,
  });

  const error = formError || (fetchError as Error);
  const isEditing = deepScanVersionId !== undefined;

  // DeepScan versions should not be updated, so the only value for a patch
  // request is the hasCriticalBug setting
  const onSubmit = useCallback(
    (values: DeepScanVersionFormValues) => {
      const valuesToSubmit = isEditing ? { hasCriticalBug: values.hasCriticalBug } : values;

      handleSubmit(valuesToSubmit);
    },
    [isEditing, handleSubmit],
  );

  // Use the key prop to automaticly initialize the form in the dialog when the
  // deepScan version changes
  return (
    <DeepScanVersionFormDialog
      deepScanVersion={deepScanVersion || newDeepScanVersion}
      error={error}
      isLoadingDeepScanVersion={isLoading}
      isOpen={open}
      key={deepScanVersion?.id}
      onClose={handleClose}
      onOpen={handleOpen}
      onSubmit={onSubmit}
    />
  );
};
