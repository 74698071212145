import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';

import { ResetDevicesModal } from './ResetDevicesModal';

export const ResetDevicesButton = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <LoadingButton
        color="primary"
        onClick={handleOpen}
        size="small"
        startIcon={<CachedRoundedIcon />}
        variant="text"
      >
        Reset Devices
      </LoadingButton>
      <ResetDevicesModal onClose={handleClose} open={open} />
    </>
  );
};
