import { MenuItem, type Theme } from '@mui/material';
import type { FC, ComponentProps, ReactNode } from 'react';
import { Link as RouterLink, useLocation, useMatch } from 'react-router-dom';

import { Link } from '@components/Util';

export interface AppBarNavLinkProps extends ComponentProps<typeof Link> {
  children: ReactNode;
  isMobile: boolean;
  matchPattern: string;
}

export const AppBarNavLink: FC<AppBarNavLinkProps> = ({
  children,
  isMobile,
  matchPattern,
  to,
  ...linkProps
}) => {
  const isActive = useMatch(matchPattern) != null;
  const location = useLocation();
  const linkTo = isActive ? location : to;

  if (isMobile)
    return (
      <MenuItem {...linkProps} component={RouterLink} selected={isActive} to={linkTo}>
        {children}
      </MenuItem>
    );

  return (
    <Link
      {...linkProps}
      color={isActive ? 'primary' : 'inherit'}
      sx={(theme: Theme) => ({
        '&.MuiLink-root:hover, &.MuiLink-root:focus': !isMobile && {
          color: theme.palette.primary.main,
        },
        fontSize: {
          lg: theme.typography.body1.fontSize,
          md: theme.typography.body2.fontSize,
        },
      })}
      to={linkTo}
      underline="none"
    >
      {children}
    </Link>
  );
};
