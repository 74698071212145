import type { FC } from 'react';

import { VrsAccountsTable } from '@components/VrsAccountTable';

export const VrsAccountsListPage: FC = () => {
  return (
    <div data-testid="vrs-accounts-list-page">
      <VrsAccountsTable syncWithQueryParams />
    </div>
  );
};
