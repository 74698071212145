import type { FC } from 'react';

import { ScanDeviceDetail } from '@components/ScanDeviceDetail';
import { useScrollTop } from '@hooks/useScrollTop';

export const ScanDeviceDetailPage: FC = () => {
  useScrollTop();

  return (
    <div data-testid="scan-device-detail-page">
      <ScanDeviceDetail />
    </div>
  );
};
