import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterIcon from '@mui/icons-material/FilterList';
import {
  Accordion,
  AccordionSummary,
  Stack,
  Typography,
  AccordionDetails,
  type AccordionProps,
  Badge,
} from '@mui/material';
import type { FC } from 'react';

export interface SearchFilterAccordionProps extends AccordionProps {
  numberOfActiveFilters: number;
}

export const SearchFilterAccordion: FC<SearchFilterAccordionProps> = ({
  children,
  numberOfActiveFilters,
  ...accordionProps
}) => {
  return (
    <Accordion {...accordionProps}>
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
        id="panel1a-header"
      >
        <Stack direction="row" spacing={2}>
          <Badge
            aria-label="number of active filters"
            badgeContent={numberOfActiveFilters}
            color="primary"
            title="number of active filters"
          >
            <FilterIcon />
          </Badge>
          <Typography>Search and Filter</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
