import { useEnvironment } from '@hooks/useEnvironment';

export const useDeviceScansDashboard = () => {
  const { grafanaScansDashboardUrl, isProd } = useEnvironment();

  const getDeviceScansDashboardUrl = (scanDeviceId: string) => {
    const params = new URLSearchParams({
      orgId: '1',
      'var-environment': isProd ? 'PROD' : 'TEST',
      'var-deviceId': scanDeviceId,
    });

    return `${grafanaScansDashboardUrl}?${params}`;
  };

  return {
    getDeviceScansDashboardUrl,
  };
};
