import type { GridColDef, GridSortDirection } from '@mui/x-data-grid';

import type { TableProps } from '@components/Table';
import type { FilterSortProps } from '@hooks/useTableFilterSort';

import {
  booleanCellRenderer,
  clientCellRenderer,
  hardwareVersionCellRenderer,
  remoteUpdateStatusCellRenderer,
  vrsTypeCellRenderer,
} from './cellRenderers';

export const initialTableFilterSortArgs: Partial<FilterSortProps> = {
  sort: { field: 'name', sort: 'asc' as GridSortDirection },
};

export const columns: GridColDef[] = [
  {
    disableColumnMenu: true,
    field: 'name',
    filterable: false,
    headerName: 'Name',
    hideable: false,
    sortable: true,
    flex: 1,
    type: 'string',
    minWidth: 200,
  },
  {
    disableColumnMenu: true,
    field: 'clientOrganization',
    filterable: false,
    headerName: 'Client',
    sortable: true,
    width: 300,
    type: 'string',
    renderCell: clientCellRenderer,
  },
  {
    disableColumnMenu: true,
    field: 'vrsType',
    filterable: false,
    headerName: 'VRS Type',
    sortable: true,
    width: 160,
    type: 'string',
    renderCell: vrsTypeCellRenderer,
  },
  {
    disableColumnMenu: true,
    field: 'shouldUpdateAppToNextVersion',
    filterable: false,
    headerName: 'Update to next App Version',
    sortable: true,
    width: 200,
    type: 'string',
    hideable: true,
    renderCell: booleanCellRenderer,
  },
  {
    disableColumnMenu: true,
    field: 'deepScanVersion',
    filterable: false,
    headerName: 'DeepScan Version',
    sortable: true,
    width: 150,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'remoteUpdateStatus',
    filterable: false,
    headerName: 'Update Status',
    sortable: false,
    width: 130,
    type: 'string',
    renderCell: remoteUpdateStatusCellRenderer,
  },
  {
    disableColumnMenu: true,
    field: 'osVersion',
    filterable: false,
    headerName: 'iOS Version',
    sortable: true,
    width: 110,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'hardwareVersion',
    filterable: false,
    headerName: 'Hardware Version',
    sortable: true,
    width: 180,
    type: 'string',
    renderCell: hardwareVersionCellRenderer,
  },
  {
    disableColumnMenu: true,
    field: 'id',
    filterable: false,
    headerName: 'ID',
    sortable: true,
    width: 300,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'udid',
    filterable: false,
    headerName: 'UDID',
    sortable: true,
    width: 240,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'mdmId',
    filterable: false,
    headerName: 'MDMID',
    sortable: true,
    width: 90,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'serialNumber',
    filterable: false,
    headerName: 'Serial Number',
    sortable: true,
    width: 150,
    type: 'string',
  },
];

export const columnVisibilityModelDesktop: TableProps['columnVisibilityModel'] = {
  shouldUpdateAppToNextVersion: false,
  hardwareVersion: false,
  id: false,
  udid: false,
};

export const columnVisibilityModelMobile: TableProps['columnVisibilityModel'] = {
  hardwareVersion: false,
  shouldUpdateAppToNextVersion: false,
  id: false,
  udid: false,
  mdmId: false,
  serialNumber: false,
};
