import type { FC } from 'react';

import { ScanDevicesTable } from '@components/ScanDevicesTable';

export const ScanDevicesListPage: FC = () => {
  return (
    <div data-testid="scan-devices-list-page">
      <ScanDevicesTable syncWithQueryParams />
    </div>
  );
};
