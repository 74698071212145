import type { FC } from 'react';

import { ControlledTextField } from '@components/Form';
import type { ControlledTextFieldProps } from '@components/Form';

import type { DeepScanVersionFormValues } from './DeepScanVersionFormDialog';

export type DeepScanVersionControlledTextFieldProps =
  ControlledTextFieldProps<DeepScanVersionFormValues>;

export const DeepScanVersionControlledTextField: FC<DeepScanVersionControlledTextFieldProps> = ({
  controllerProps,
  textFieldProps,
}) => {
  return (
    <ControlledTextField
      controllerProps={{
        rules: { required: 'This field is required' },
        ...controllerProps,
      }}
      textFieldProps={{ helperText: ' ', required: true, ...textFieldProps }}
    />
  );
};
