/**
 * Taken from backoffice frontend
 */
import type { GridSortModel, GridSortDirection } from '@mui/x-data-grid';
import isEmpty from 'lodash.isempty';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type TableSorting = GridSortModel[0];

export type TableFilters = Record<string, string>;

export interface FilterSortProps {
  filters: TableFilters; // filters are arbitrary key-values which are specific to our datatable plugin
  page: number;
  size: number;
  selectedRowIds: string[];
  sort?: TableSorting;
}

const defaultProps: FilterSortProps = {
  filters: {},
  page: 0,
  selectedRowIds: [],
  size: 10,
};

const parseParams = (params: URLSearchParams): FilterSortProps => {
  const { page, size, sort, rows, ...filters } = Object.fromEntries(params.entries());
  const [field, direction] = sort?.split(',') ?? [];

  return {
    ...defaultProps,
    page: Number(page),
    selectedRowIds: rows?.split(',') ?? [],
    size: Number(size),
    sort: field && direction ? { field, sort: direction as GridSortDirection } : undefined,
    filters,
  };
};

interface FilterSortFunctions {
  setFilters: (filters: TableFilters) => void;
  setPageNumber: (number: number) => void;
  setPageSize: (number: number) => void;
  setSelectedRowIds: (rowsIds: string[]) => void;
  setTableSorting: (sorting?: TableSorting) => void;
}

export type TableFilterSortHookReturn = FilterSortProps & FilterSortFunctions;

type TableFilterSortHook = (defaultParams: Partial<FilterSortProps>) => TableFilterSortHookReturn;

const useWithStateHook: TableFilterSortHook = (props) => {
  const [params, setParams] = useState({ ...defaultProps, ...props });

  const setPageNumber = (pageNumber: number) => setParams({ ...params, page: pageNumber });
  const setPageSize = (pageSize: number) => setParams({ ...params, size: pageSize });
  const setTableSorting = (sorting?: TableSorting | undefined) =>
    setParams({ ...params, sort: sorting ?? defaultProps.sort });
  const setFilters = (filters: TableFilters) => setParams({ ...params, filters });
  const setSelectedRowIds = (selectedRowIds: string[]) => setParams({ ...params, selectedRowIds });

  return {
    ...params,
    setTableSorting,
    setFilters,
    setPageNumber,
    setPageSize,
    setSelectedRowIds,
  };
};

const useWithQueryParams: TableFilterSortHook = (props) => {
  const initialProps = { ...defaultProps, ...props };
  const [currentSearchParams, setCurrentSearchParams] = useSearchParams();

  // useMemo prevents infinite rerenders here.
  return useMemo(() => {
    const nextSearchParams: URLSearchParams = new URLSearchParams(currentSearchParams.toString());

    // TODO [https://gitlab.com/deepup-gmbh/team-backoffice/-/issues/48]
    //  All this double checking is because this hook is triggered multiple times at initialisation which makes it lose track of `props` apparently.
    //  Please fix unnecessary rerenders and callback executions by our app and xGrid.
    if (!nextSearchParams.get('page') && initialProps?.page !== undefined) {
      nextSearchParams.set('page', initialProps.page.toString());
    }
    if (!nextSearchParams.get('size') && initialProps?.size !== undefined) {
      nextSearchParams.set('size', initialProps.size.toString());
    }
    if (!nextSearchParams.get('sort') && initialProps?.sort !== undefined) {
      nextSearchParams.set('sort', `${initialProps.sort.field},${initialProps.sort.sort}`);
    }
    if (!nextSearchParams.get('rows') && initialProps?.selectedRowIds?.length > 0) {
      nextSearchParams.set('rows', initialProps.selectedRowIds.join(','));
    }

    const updateParams = () => {
      const nextSearch = nextSearchParams.toString();

      if (currentSearchParams.toString() === nextSearch) return;
      // search parameters actually need to change -> Update URL!
      setCurrentSearchParams(nextSearch, { replace: false });
    };

    const setQueryParams = (params: TableFilters) => {
      Object.entries(params).forEach(([name, value]) => {
        if (!isEmpty(value)) {
          nextSearchParams.set(name, value);
        } else {
          nextSearchParams.delete(name);
        }
      });
      updateParams();
    };

    const removeQueryParams = (keys: string[]) => {
      keys.forEach((key) => nextSearchParams.delete(key));
      updateParams();
    };

    const setSelectedRowIdQueryParams = (selectedRowIds: string[]) => {
      nextSearchParams.set('rows', selectedRowIds.join(','));
      updateParams();
    };

    const setPageNumber = (pageNumber: number) => {
      const nextPageNumber = Number.parseInt(nextSearchParams.get('page') as string);

      // TODO
      // This makes sure the pagenumber does not get updated with the initial value (0)
      // when the user navigates back from a detail page to the table.
      // Pagination allows changing the page number by +1 or -1 only.
      if (nextPageNumber - 1 !== pageNumber && nextPageNumber + 1 !== pageNumber) return;

      setQueryParams({ page: pageNumber.toString() });
    };
    const setPageSize = (pageSize: number) => setQueryParams({ size: pageSize.toString() });
    const setTableSorting = (sorting?: TableSorting | undefined) => {
      if (!sorting) {
        removeQueryParams(['sort']);
      } else {
        const { field, sort } = sorting;

        setQueryParams({ sort: `${field},${sort}` });
      }
    };
    const setFilters = (filters: TableFilters) => {
      if (isEmpty(filters)) {
        const keysToRemove = Array.from(nextSearchParams.keys()).filter(
          (key) => !['size', 'sort'].includes(key),
        );

        removeQueryParams(keysToRemove);
      } else {
        // Reset page number und selected rows if filters are set.
        setQueryParams({ ...filters, page: '0', rows: '' });
      }
    };

    const setSelectedRowIds = (selectedRowIds: string[]) => {
      if (isEmpty(selectedRowIds)) {
        removeQueryParams(['rows']);
      } else {
        setSelectedRowIdQueryParams(selectedRowIds);
      }
    };

    return {
      ...parseParams(nextSearchParams),
      setTableSorting,
      setFilters,
      setPageNumber,
      setPageSize,
      setSelectedRowIds,
    };
  }, [currentSearchParams]);
};

export const useTableFilterSort = (syncWithQueryParams: boolean): TableFilterSortHook =>
  syncWithQueryParams ? useWithQueryParams : useWithStateHook;
