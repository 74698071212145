import type { FC } from 'react';

import { DeepScanVersionsTable } from '@components/DeepScanVersionsTable';

export const DeepScanVersionsListPage: FC = () => {
  return (
    <div data-testid="deep-scan-versions-list-page">
      <DeepScanVersionsTable syncWithQueryParams />
    </div>
  );
};
