import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import type { FieldError } from 'react-hook-form';

export interface FormErrorMessageProps {
  error?: FieldError;
}

export const FormErrorMessage: FC<FormErrorMessageProps> = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <Box alignItems="center" component="span" display="flex">
      <ErrorIcon
        sx={{
          fontSize: (theme) => theme.typography.body2.fontSize,
          marginRight: (theme) => theme.spacing(0.6),
        }}
      />
      {error.message}
    </Box>
  );
};
