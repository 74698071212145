import type { GridRenderCellParams } from '@mui/x-data-grid';

import type { ScanDevice } from 'types/api';

import { DeepScanVersionStatusComponent } from './DeepScanVersionStatus';
import { TimeDifference } from './TimeDifference';

export const remoteUpdateStatusCellRenderer = ({ row }: { row: ScanDevice }) => {
  return <DeepScanVersionStatusComponent deepScanVersionStatus={row.deepScanVersionStatus} />;
};

export const timeDiffCellRenderer = ({
  value,
}: GridRenderCellParams<string | null, ScanDevice>) => {
  return <TimeDifference start={value} />;
};

export const clientCellRenderer = ({ row }: { row: ScanDevice }) => row.clientOrganization?.name;
export const vrsTypeCellRenderer = ({ row }: { row: ScanDevice }) => row.vrsType.name;

export const hardwareVersionCellRenderer = ({ row }: { row: ScanDevice }) =>
  row.hardwareVersion.name;

export const booleanCellRenderer = ({ value }: GridRenderCellParams<boolean>) =>
  value ? 'Yes' : 'No';
