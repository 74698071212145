import type { ThemeOptions } from '@mui/material/styles';

export const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#00AB84',
      light: 'rgb(51, 187, 156)',
      dark: 'rgb(0, 119, 92)',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#373737',
    },
  },
};
