import type { FieldValues } from 'react-hook-form';

import { ControlledSwitch, type ControlledSwitchProps } from '@components/Form';

export const ControlledSwitchWithInPlaceEdit = <FormValuesType extends FieldValues>({
  switchProps,
  ...props
}: ControlledSwitchProps<FormValuesType>) => (
  <ControlledSwitch
    {...props}
    switchProps={{
      ...switchProps,
      size: 'small',
    }}
  />
);
