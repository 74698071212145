import { SharedProps, useSnackbar } from 'notistack';
import { useState, useMemo, useCallback } from 'react';
import type { UnpackNestedValue } from 'react-hook-form';

export const useInPlaceEdit = <FormValues>({
  snackbarSuccessProps,
  update,
}: {
  snackbarSuccessProps?: { message?: string; props?: SharedProps };
  update: (data: UnpackNestedValue<FormValues>) => Promise<void>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);

  const onSubmit = useCallback(
    async (data: UnpackNestedValue<FormValues>) => {
      try {
        await update(data);

        enqueueSnackbar(snackbarSuccessProps?.message || 'Update successful!', {
          autoHideDuration: 1000,
          variant: 'success',
          ...snackbarSuccessProps?.props,
        });
        setIsEditing(false);
      } catch (error) {
        enqueueSnackbar((error as Error).toString(), {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, setIsEditing, update],
  );

  return useMemo(
    () => ({
      isEditing,
      onSubmit,
      setIsEditing,
    }),
    [isEditing, setIsEditing],
  );
};
