import { LoadingButton } from '@mui/lab';
import { Button, Grid, Radio, RadioGroup, Stack, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  GridValidRowModel,
  gridPageSizeSelector,
  selectedGridRowsCountSelector,
  selectedGridRowsSelector,
  useGridApiContext,
  useGridSelector,
  type GridApiCommon,
  type GridRowId,
} from '@mui/x-data-grid';
import {
  useSnackbar,
  type OptionsObject,
  type SharedProps,
  type SnackbarKey,
  type SnackbarMessage,
} from 'notistack';
import type { ChangeEvent, FC } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { pleaseSelectOption } from '@components/Form';
import { useScanDeviceUpdates } from '@hooks/api';
import type { ScanDeviceUpdateRequest } from 'types/api';

import { ControlledMdmGroupSelect } from './ControlledMdmGroupSelect';
import { ControlledVrsTypeSelect } from './ControlledVrsTypeSelect';

export interface EditScanDeviceFormValues {
  mdmGroup: string;
  shouldUpdateAppToNextVersion: string;
  vrsType: string;
}

export interface EditScanDeviceFooterProps {
  mutate: () => void;
}

const defaultValues: EditScanDeviceFormValues = {
  mdmGroup: pleaseSelectOption.id,
  shouldUpdateAppToNextVersion: pleaseSelectOption.id,
  vrsType: pleaseSelectOption.id,
};

const selectRules = {
  validate: (value: string) => (value !== pleaseSelectOption.id ? true : 'Please select an option'),
};

const showErrorSnackbar = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  closeSnackbar: (key?: SnackbarKey | undefined) => void,
  selectedRows: Map<GridRowId, GridValidRowModel>,
  failed: string[],
  errors: Set<string>,
) => {
  const selectedRowsArray = Array.from(selectedRows.values());
  const failedRows = selectedRowsArray
    .filter((row) => failed.includes(row.id))
    .map((row) => row.name);
  const errorsAsArray = Array.from(errors);

  enqueueSnackbar(
    <Stack gap={0.5}>
      <Typography fontWeight="bold" variant="body2">
        {`Failed to update ${failed.length} of ${selectedRowsArray.length} ${
          failed.length > 1 ? 'ScanDevices' : 'ScanDevice'
        }:`}
      </Typography>
      <div>{`${failedRows.join(', ')}`}</div>
      <div>{`${errorsAsArray.length > 1 ? 'Reasons' : 'Reason'}: ${errorsAsArray.join(', ')}`}</div>
    </Stack>,
    {
      action: (key) => (
        <Button
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
          variant="text"
        >
          Dismiss
        </Button>
      ),
      persist: true,
      variant: 'error',
    },
  );
};

type SelectedPropertyToSubmit = 'mdmGroup' | 'shouldUpdateAppToNextVersion' | 'vrsType';

export const EditScanDeviceFooter: FC<EditScanDeviceFooterProps> = ({ mutate }) => {
  const [selectedPropertyToSubmit, setSelectedPropertyToSubmit] =
    useState<SelectedPropertyToSubmit>('shouldUpdateAppToNextVersion');
  const apiRef = useGridApiContext();
  const selectedRowsCount = useGridSelector(apiRef, selectedGridRowsCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const selectedRows = useGridSelector<GridApiCommon, Map<GridRowId, GridValidRowModel>>(
    apiRef,
    selectedGridRowsSelector,
  );

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { updateScanDevices } = useScanDeviceUpdates();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<EditScanDeviceFormValues>({
    defaultValues,
  });

  // Hide this footer and show the normal footer again
  const showDefaultFooter = () => {
    apiRef.current.setSelectionModel([]);
    reset();
  };

  const onSubmit = async (values: EditScanDeviceFormValues) => {
    try {
      const ids = Array.from(selectedRows.keys()) as string[];
      let valuesToSubmit: ScanDeviceUpdateRequest;
      const snackbarSuccessProps: { message: string; props: SharedProps } = {
        message: `${ids.length} ${
          ids.length > 1 ? 'ScanDevices' : 'ScanDevice'
        } updated successfully!`,
        props: {
          autoHideDuration: 3000,
          variant: 'success',
        },
      };

      switch (selectedPropertyToSubmit) {
        case 'shouldUpdateAppToNextVersion':
          valuesToSubmit = {
            shouldUpdateAppToNextVersion: Boolean(values.shouldUpdateAppToNextVersion === 'true'),
          };
          break;
        case 'vrsType':
          valuesToSubmit = {
            vrsType: values.vrsType,
          };
          break;
        case 'mdmGroup':
          valuesToSubmit = {
            mdmGroup: values.mdmGroup,
          };
          // Change snackbar variant and message to indicate the group does not change instantly
          snackbarSuccessProps.message = `Request for switching the MDM group has been sent for ${
            ids.length
          } ${
            ids.length > 1 ? 'ScanDevices' : 'ScanDevice'
          }! It may take a while before the changes take effect.`;
          snackbarSuccessProps.props.autoHideDuration = 5000;
          snackbarSuccessProps.props.variant = 'info';
          break;
        default: {
          // Type guard against the selectedPropertyToSubmit having another value than those handled before
          const unhandledCase: never = selectedPropertyToSubmit;

          throw new Error(`UnhandledCase for selectedPropertyToSubmit: ${unhandledCase}`);
        }
      }

      // Update the ScanDevices
      const { errors, failed } = await updateScanDevices(ids, valuesToSubmit);

      // Because for every ScanDevice we update, we send one request, we have to give the user
      // detailed feedback in case of errors
      if (failed.length > 0) {
        showErrorSnackbar(enqueueSnackbar, closeSnackbar, selectedRows, failed, errors);
      } else {
        enqueueSnackbar(snackbarSuccessProps.message, snackbarSuccessProps.props);
      }

      showDefaultFooter();
    } catch (error) {
      enqueueSnackbar((error as Error).toString(), {
        variant: 'error',
      });
    } finally {
      // Refresh the swr cache so the table data is updated
      mutate();
    }
  };

  const handleSelectPropertyToSubmitRadioGroupOnChange = (
    _: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setSelectedPropertyToSubmit(value as SelectedPropertyToSubmit);
    reset();
  };

  const isVrsTypeChecked = selectedPropertyToSubmit === 'vrsType';
  const isMdmGroupChecked = selectedPropertyToSubmit === 'mdmGroup';

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingY: (theme) => theme.spacing(2),
      }}
    >
      <Stack
        alignItems="center"
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="flex-end"
        spacing={2}
        width="100%"
      >
        <Typography sx={{ flex: 1, width: { xs: '100%', md: 'auto' }, whiteSpace: 'nowrap' }}>
          {selectedRowsCount} of {pageSize} selected
        </Typography>
        <Box sx={{ width: { xs: '100%' } }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RadioGroup
              name="selected-property-to-submit"
              onChange={handleSelectPropertyToSubmitRadioGroupOnChange}
              value={selectedPropertyToSubmit}
            >
              <Grid container direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Grid alignItems="center" display="flex" flex={{ md: 1, lg: 0 }} item>
                  <Radio value="vrsType" />
                  <ControlledVrsTypeSelect
                    controllerProps={{
                      control,
                      name: 'vrsType',
                      rules: isVrsTypeChecked ? selectRules : undefined,
                    }}
                    textFieldProps={{
                      disabled: isSubmitting || !isVrsTypeChecked,
                      label: 'Type',
                    }}
                  />
                </Grid>
                <Grid alignItems="center" display="flex" flex={{ md: 1, lg: 0 }} item>
                  <Radio value="mdmGroup" />
                  <ControlledMdmGroupSelect
                    controllerProps={{
                      control,
                      name: 'mdmGroup',
                      rules: isMdmGroupChecked ? selectRules : undefined,
                    }}
                    textFieldProps={{
                      disabled: isSubmitting || !isMdmGroupChecked,
                      label: 'MDM Group',
                    }}
                  />
                </Grid>
                <Grid
                  alignItems="center"
                  display="flex"
                  item
                  justifyContent="flex-end"
                  lg={2}
                  md={12}
                  sm={4}
                  xl={2}
                >
                  <Button disabled={isSubmitting} onClick={showDefaultFooter} type="button">
                    Cancel
                  </Button>

                  <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </RadioGroup>
          </form>
        </Box>
      </Stack>
    </Toolbar>
  );
};
