import { Alert } from '@mui/material';
import { useCallback } from 'react';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';

import { ControlledSelect } from '@components/Form';
import { UseMdmInfoMutate, useScanDeviceUpdate } from '@hooks/api';
import { useInPlaceEdit } from '@hooks/useInPlaceEdit';
import { useMdmGroupSelect } from '@hooks/useMdmGroupSelect';
import type { ScanDeviceDetail, ScanDeviceMdmInfo } from 'types/api';

import { InPlaceEdit } from './InPlaceEdit';

interface FormValues {
  mdmGroup: string;
}

export interface MdmGroupInPlaceChangeButtonProps {
  mutateScanDeviceMdmInfo: UseMdmInfoMutate;
  scanDeviceDetail: ScanDeviceDetail;
  scanDeviceMdmInfo?: ScanDeviceMdmInfo;
}

export const MdmGroupInPlaceChangeButton: FC<MdmGroupInPlaceChangeButtonProps> = ({
  mutateScanDeviceMdmInfo,
  scanDeviceDetail,
  scanDeviceMdmInfo,
}) => {
  const { trigger, isMutating } = useScanDeviceUpdate(scanDeviceDetail.id);
  const selectedMdmGroup = scanDeviceMdmInfo?.mdmGroup;
  const { options, mdmGroups } = useMdmGroupSelect();

  const update = useCallback(
    async (data: FormValues) => {
      const mdmGroup = options.find((mdmGroupOption) => mdmGroupOption.id === data.mdmGroup);

      if (mdmGroup && scanDeviceMdmInfo) {
        await trigger(data);

        mutateScanDeviceMdmInfo((current) => {
          if (!current) return current;

          return {
            ...current,
            mdmGroup: {
              ...mdmGroup,
              id: Number.parseInt(mdmGroup.id),
            },
          };
        });
      }
    },
    [options, mutateScanDeviceMdmInfo, trigger],
  );

  const { isEditing, onSubmit, setIsEditing } = useInPlaceEdit<FormValues>({
    snackbarSuccessProps: {
      message:
        'Request for switching the MDM group has been sent! It may take a while before the changes take effect.',
      props: {
        autoHideDuration: 5000,
        variant: 'info',
      },
    },
    update,
  });

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      mdmGroup: selectedMdmGroup?.id?.toString() ?? '',
    },
  });

  const onChangeHandler = handleSubmit(onSubmit);

  const validMdmGroup =
    selectedMdmGroup && mdmGroups?.find((mdmGroup) => selectedMdmGroup.id === mdmGroup.id);

  const selectedGroupCanNotBeChanged = Boolean(
    validMdmGroup && selectedMdmGroup && selectedMdmGroup.changeable === false,
  );

  const selectedGroupIsUpdateGroup =
    selectedGroupCanNotBeChanged && selectedMdmGroup && selectedMdmGroup.selectable === false;

  return (
    <>
      <InPlaceEdit
        disabled={selectedGroupCanNotBeChanged}
        isEditing={isEditing}
        isUpdating={isMutating}
        selectedValueText={scanDeviceMdmInfo?.mdmGroup?.name || ''}
        setIsEditing={setIsEditing}
      >
        <ControlledSelect
          controllerProps={{ control, name: 'mdmGroup' }}
          options={options}
          textFieldProps={{
            disabled: selectedGroupCanNotBeChanged,
            onChange: onChangeHandler,
            size: 'small',
          }}
        />
      </InPlaceEdit>
      {selectedGroupIsUpdateGroup ? (
        <Alert icon={false} severity="info" variant="standard">
          The MDM group cannot be changed at the moment because an update is in progress.
        </Alert>
      ) : null}
    </>
  );
};
