import type { FC } from 'react';
import { useCallback } from 'react';

import { useActivePhasedRollout, usePhasedRolloutDelete, usePhasedRolloutUpsert } from '@hooks/api';
import { useFormDialog } from '@hooks/useFormDialog';
import type { PhasedRollout } from 'types/api';

import {
  PhasedRolloutFormDialog,
  PhasedRolloutFormValuesAfterSubmit,
} from './PhasedRolloutFormDialog';
import type { PhasedRolloutFormValues } from './PhasedRolloutFormDialog';

export interface PhasedRolloutFormDialogContainerProps {
  onClose: () => void;
}

export const PhasedRolloutFormDialogContainer: FC<PhasedRolloutFormDialogContainerProps> = ({
  onClose,
}) => {
  // Phased roolouts should not be updated, so there is no id to pass to the upsert hook
  const { trigger: triggerUpsert } = usePhasedRolloutUpsert();
  const { trigger: triggerDelete } = usePhasedRolloutDelete();
  const {
    data: phasedRollout,
    error: fetchError,
    isLoading,
    mutate,
  } = useActivePhasedRollout({
    revalidateOnReconnect: false,
    revalidateIfStale: true,
    refreshInterval: 10000,
    errorRetryInterval: 10000,
  });

  const {
    formError,
    handleClose,
    handleOpen,
    newEntity: newPhasedRollout,
    handleSubmit,
    open,
  } = useFormDialog<PhasedRollout, PhasedRolloutFormValues, PhasedRolloutFormValuesAfterSubmit>({
    onClose: () => {
      mutate();
      onClose();
    },
    upsertCb: triggerUpsert,
  });

  const handleDelete = useCallback(async () => {
    await triggerDelete();
    mutate();
  }, [triggerDelete, mutate]);

  const error = formError || fetchError;

  // Phased rollouts should not be updated
  const onSubmit = useCallback(
    (values: PhasedRolloutFormValues) => {
      const { fullRolloutAfter, ...rest } = values;

      handleSubmit({
        ...rest,
        fullRolloutAfter: fullRolloutAfter
          ? new Date(fullRolloutAfter).toISOString()
          : fullRolloutAfter,
      });
    },
    [handleSubmit],
  );

  return (
    <PhasedRolloutFormDialog
      error={error}
      isLoadingActivePhasedRollout={isLoading}
      isOpen={open}
      key={phasedRollout?.id}
      onClose={handleClose}
      onDelete={handleDelete}
      onOpen={handleOpen}
      onSubmit={onSubmit}
      phasedRollout={phasedRollout || newPhasedRollout}
    />
  );
};
