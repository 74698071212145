import type { FC } from 'react';

import { DeepScanVersionStatusComponent } from '@components/ScanDevicesTable';
import type { UseMdmInfoMutate, UseScanDeviceMutate } from '@hooks/api';
import type { ScanDeviceDetail, ScanDeviceMdmInfo } from 'types/api';

import { MdmGroupInPlaceChangeButton } from './MdmGroupInPlaceChangeButton';
import { Section } from './Section';
import { SectionRow } from './SectionRow';
import { SectionRowWithLoading } from './SectionRowWithLoading';
import { UnlockInternalFeaturesInPlaceChangeButton } from './UnlockInternalFeaturesInPlaceChangeButton';
import { VrsAccountInPlaceChangeButton } from './VrsAccountInPlaceChangeButton';

export interface ProfileSectionProps {
  mdmInfo?: ScanDeviceMdmInfo;
  mdmInfoIsLoading: boolean;
  mutateScanDeviceDetail: UseScanDeviceMutate;
  mutateScanDeviceMdmInfo: UseMdmInfoMutate;
  scanDeviceDetail: ScanDeviceDetail;
}

export const ProfileSection: FC<ProfileSectionProps> = ({
  mdmInfo,
  mdmInfoIsLoading,
  mutateScanDeviceDetail,
  mutateScanDeviceMdmInfo,
  scanDeviceDetail,
}) => {
  return (
    <Section title="Profile">
      <SectionRow label="Client">{scanDeviceDetail.clientOrganization?.name}</SectionRow>
      <SectionRow label="VRS Type">
        <VrsAccountInPlaceChangeButton
          mutateScanDeviceDetail={mutateScanDeviceDetail}
          scanDeviceDetail={scanDeviceDetail}
        />
      </SectionRow>
      <SectionRow label="iOS Version">{scanDeviceDetail.osVersion}</SectionRow>
      <SectionRow label="App (Software) Version">
        {mdmInfo?.deepScanVersion ?? scanDeviceDetail.deepScanVersion}
      </SectionRow>
      <SectionRow label="Update Status">
        <DeepScanVersionStatusComponent
          deepScanVersionStatus={scanDeviceDetail.deepScanVersionStatus}
          showText
        />
      </SectionRow>
      <SectionRowWithLoading label="Internal features available" loading={mdmInfoIsLoading}>
        <UnlockInternalFeaturesInPlaceChangeButton
          mutateScanDeviceMdmInfo={mutateScanDeviceMdmInfo}
          scanDeviceDetail={scanDeviceDetail}
          scanDeviceMdmInfo={mdmInfo}
        />
      </SectionRowWithLoading>
      <SectionRowWithLoading label="MDM Group" loading={mdmInfoIsLoading}>
        <MdmGroupInPlaceChangeButton
          mutateScanDeviceMdmInfo={mutateScanDeviceMdmInfo}
          scanDeviceDetail={scanDeviceDetail}
          scanDeviceMdmInfo={mdmInfo}
        />
      </SectionRowWithLoading>
    </Section>
  );
};
