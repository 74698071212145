import type { FC } from 'react';

import type { UseScanDeviceMutate } from '@hooks/api';
import type { ScanDeviceDetail, ScanDeviceMdmInfo } from 'types/api';

import { BatteryIndicator } from './BatteryIndicator';
import { HardwareVersionInPlaceChangeButton } from './HardwareVersionInPlaceChangeButton';
import { Section } from './Section';
import { SectionRow } from './SectionRow';
import { SectionRowWithLoading } from './SectionRowWithLoading';

export interface TechnicalInformationSectionProps {
  mdmInfo?: ScanDeviceMdmInfo;
  mdmInfoIsLoading: boolean;
  mutateScanDeviceDetail: UseScanDeviceMutate;
  scanDeviceDetail: ScanDeviceDetail;
}

export const TechnicalInformationSection: FC<TechnicalInformationSectionProps> = ({
  mdmInfo,
  mdmInfoIsLoading,
  mutateScanDeviceDetail,
  scanDeviceDetail,
}) => {
  return (
    <Section imageProps={{ src: '/assets/scan-device.svg' }} title="Technical Information">
      <SectionRow label="Status">{scanDeviceDetail.status}</SectionRow>
      <SectionRowWithLoading label="Battery" loading={mdmInfoIsLoading}>
        <BatteryIndicator value={mdmInfo?.batteryLevel} />
      </SectionRowWithLoading>
      <SectionRow label="Hardware Version">
        <HardwareVersionInPlaceChangeButton
          mutateScanDeviceDetail={mutateScanDeviceDetail}
          scanDeviceDetail={scanDeviceDetail}
        />
      </SectionRow>
      <SectionRow label="Serial Number">{scanDeviceDetail.serialNumber}</SectionRow>
      <SectionRow label="Model Name">{scanDeviceDetail.modelName}</SectionRow>
      <SectionRow label="Model">{scanDeviceDetail.model}</SectionRow>
      <SectionRow label="Product Name">{scanDeviceDetail.productName}</SectionRow>
    </Section>
  );
};
