import { type SwrConfig, useScanDeviceApiGetQuery } from '@hooks/api/useScanDeviceApiGetQuery';
import type { PhasedRollout } from 'types/api';

export const useActivePhasedRollout = (swrConfig?: SwrConfig<PhasedRollout>) => {
  const { data, error, isLoading, ...rest } = useScanDeviceApiGetQuery<PhasedRollout>(
    '/phased-rollouts',
    swrConfig,
  );

  /* If the error is a 404, we don't want to treat it as such,
   * because it just means that no phased rollout is active at the moment.
   */
  const has404 = error && error.status === 404;

  return {
    data: has404 ? undefined : data,
    error: has404 ? undefined : error,
    isLoading: has404 ? false : isLoading,
    ...rest,
  };
};
