import type { FC } from 'react';

import { HardwareVersionsTable } from '@components/HardwareVersionsTable';

export const HardwareVersionsListPage: FC = () => {
  return (
    <div data-testid="hardware-versions-list-page">
      <HardwareVersionsTable syncWithQueryParams />
    </div>
  );
};
