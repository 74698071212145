import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  Button,
  Dialog,
  LinearProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  Alert,
  AlertTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
  InputAdornment,
} from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import type { HardwareVersion } from 'types/api';

import { HardwareVersionControlledCheckbox } from './HardwareVersionCheckbox';
import { HardwareVersionControlledTextField } from './HardwareVersionControlledTextField';
import * as classes from './HardwareVersionFormDialog.module.css';

const textFieldMetreInputProps = {
  InputProps: {
    startAdornment: <InputAdornment position="start">m</InputAdornment>,
  },
};

export type HardwareVersionFormValuesAfterSubmit = Partial<Omit<HardwareVersion, 'id'>>;
export type HardwareVersionFormValues = Omit<
  HardwareVersionFormValuesAfterSubmit,
  'antennaOffsetX' | 'antennaOffsetY' | 'antennaOffsetZ'
> & {
  antennaOffsetX?: number;
  antennaOffsetY?: number;
  antennaOffsetZ?: number;
};

const initialValues: HardwareVersionFormValues = {
  isDefault: false,
  name: '',
  antennaType: '',
  antennaOffsetX: undefined,
  antennaOffsetY: undefined,
  antennaOffsetZ: undefined,
};

export interface HardwareVersionFormDialogProps {
  error?: Error;
  onClose: () => void;
  onDelete: () => void;
  onOpen: () => void;
  onSubmit: (values: HardwareVersionFormValues) => void;
  isLoadingVrs?: boolean;
  isOpen: boolean;
  hardwareVersion: Partial<HardwareVersion>;
  hardwareVersionFetchError?: Error;
}

export const HardwareVersionFormDialog: React.FC<HardwareVersionFormDialogProps> = ({
  error,
  isOpen,
  isLoadingVrs,
  onClose,
  onDelete,
  onOpen,
  onSubmit,
  hardwareVersionFetchError,
  hardwareVersion,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<HardwareVersionFormValues>({
    defaultValues: { ...initialValues, ...hardwareVersion },
    reValidateMode: 'onSubmit',
  });

  React.useEffect(() => {
    reset({ ...initialValues, ...hardwareVersion });
  }, [hardwareVersion, reset]);

  const isEditing = hardwareVersion.id !== undefined;
  const isLoading = isSubmitting || isLoadingVrs;
  const isInputDisabled = isEditing || isLoading;
  const displayError = (error as Error) || hardwareVersionFetchError;

  return (
    <div>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button onClick={onOpen} variant="outlined">
            Create Hardware Version
          </Button>
        </Grid>
      </Grid>
      <Dialog disableEscapeKeyDown fullScreen={fullScreen} maxWidth="md" open={isOpen}>
        {isLoading && <LinearProgress />}
        <DialogTitle sx={{ display: 'flex' }}>
          <span className={classes.title}>
            {isEditing ? 'Edit Hardware Version' : 'Create Hardware Version'}
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {displayError && (
            <Alert severity="error" variant="standard">
              <AlertTitle>{displayError.toString()}</AlertTitle>
            </Alert>
          )}
          <form id="vrs-type-form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container marginTop={0.5} spacing={2}>
              <Grid item md={6} xs={12}>
                <HardwareVersionControlledTextField
                  controllerProps={{
                    control,
                    name: 'name',
                  }}
                  textFieldProps={{
                    disabled: isInputDisabled,
                    label: 'Name',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <HardwareVersionControlledTextField
                  controllerProps={{
                    control,
                    name: 'antennaType',
                  }}
                  textFieldProps={{
                    disabled: isInputDisabled,
                    label: 'Antenna Type',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <HardwareVersionControlledTextField
                  controllerProps={{
                    control,
                    name: 'antennaOffsetX',
                  }}
                  textFieldProps={{
                    ...textFieldMetreInputProps,
                    disabled: isInputDisabled,
                    label: 'Antenna Offset X in meters',
                    type: 'number',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <HardwareVersionControlledTextField
                  controllerProps={{
                    control,
                    name: 'antennaOffsetY',
                  }}
                  textFieldProps={{
                    ...textFieldMetreInputProps,
                    disabled: isInputDisabled,
                    label: 'Antenna Offset Y in meters',
                    type: 'number',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <HardwareVersionControlledTextField
                  controllerProps={{
                    control,
                    name: 'antennaOffsetZ',
                  }}
                  textFieldProps={{
                    ...textFieldMetreInputProps,
                    disabled: isInputDisabled,
                    label: 'Antenna Offset Z in meters',
                    type: 'number',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <HardwareVersionControlledCheckbox
                  checkboxProps={{
                    disabled: isLoading,
                    helperText: 'Set this Hardware Version as the default for all new devices.',
                    label: 'Default Hardware Version',
                  }}
                  controllerProps={{
                    control,
                    name: 'isDefault',
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: (theme) => (fullScreen ? theme.spacing(3) : undefined) }}>
          {isEditing && (
            <div className={classes.deleteButtonContainer}>
              <Button color="error" disabled={isLoading} onClick={handleSubmit(onDelete)}>
                Delete
              </Button>
            </div>
          )}
          <Button disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isLoading}
            form="vrs-type-form"
            type="submit"
            variant="outlined"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
