import type { FC } from 'react';

import type { ScanDeviceDetail, ScanDeviceMdmInfo } from 'types/api';

import { Section } from './Section';
import { SectionRow } from './SectionRow';
import { SectionRowWithLoading } from './SectionRowWithLoading';
import { StorageIndicator } from './StorageIndicator';

export interface UsageDataSectionProps {
  mdmInfo?: ScanDeviceMdmInfo;
  mdmInfoIsLoading: boolean;
  scanDeviceDetail: ScanDeviceDetail;
}

export const UsageDataSection: FC<UsageDataSectionProps> = ({
  mdmInfo,
  mdmInfoIsLoading,
  scanDeviceDetail,
}) => {
  return (
    <Section title="Usage Data">
      <SectionRow label="Last Scan"></SectionRow>
      <SectionRow label="Last Upload"></SectionRow>
      <SectionRowWithLoading label="Storage" loading={mdmInfoIsLoading}>
        <StorageIndicator
          available={mdmInfo?.availableDeviceCapacity}
          total={scanDeviceDetail.deviceCapacity}
        />
      </SectionRowWithLoading>
    </Section>
  );
};
