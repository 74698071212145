import { debounce } from '@mui/material';
import isEmpty from 'lodash.isempty';
import { useCallback } from 'react';
import type {
  DeepPartial,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormReset,
} from 'react-hook-form';

import { allSelectOption, pleaseSelectOption } from '@components/Form';

import type { TableFilters } from './useTableFilterSort';

const isDefaultOptionSelected = (value?: string, defaultSelectValue?: string) => {
  const defaultSelectValues = [defaultSelectValue, allSelectOption.id, pleaseSelectOption.id];

  return defaultSelectValues.indexOf(value) !== -1;
};

export const useSearchFilterForm = <FormValuesType>({
  defaultSelectValue,
  defaultValues,
  getValues,
  handleSubmit,
  onChange,
  reset,
}: {
  defaultSelectValue?: string;
  defaultValues: DeepPartial<FormValuesType>;
  getValues: UseFormGetValues<DeepPartial<FormValuesType>>;
  handleSubmit: UseFormHandleSubmit<DeepPartial<FormValuesType>>;
  onChange: (values: TableFilters) => void;
  reset: UseFormReset<DeepPartial<FormValuesType>>;
}) => {
  const onChangeHandler = useCallback(
    handleSubmit((data) => {
      const dataWithoutDefaultSelectValues = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          isDefaultOptionSelected(value, defaultSelectValue) === false ? value : '',
        ]),
      );

      onChange(dataWithoutDefaultSelectValues);
    }),
    [handleSubmit, onChange],
  );
  const debouncedOnChangeHandler = useCallback(debounce(onChangeHandler, 300), [
    debounce,
    onChangeHandler,
  ]);

  const onResetButtonClickHandler = () => {
    reset(defaultValues);
    onChange({});
  };

  const numberOfActiveFilters = Object.values(getValues()).filter(
    (value) => !isEmpty(value) && isDefaultOptionSelected(value, defaultSelectValue) === false,
  ).length;

  return {
    onChangeHandler,
    debouncedOnChangeHandler,
    numberOfActiveFilters,
    onResetButtonClickHandler,
  };
};
