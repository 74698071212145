import { useEnvironment } from '@hooks/useEnvironment';
import type { ScanDevice, ScanDeviceUpdateRequest } from 'types/api';

import { useMutationFetcher } from '../useMutationFetcher';

/**
 * @TODO This should be a single api call. Should be discussed with Tim when he is back from vacation.
 */
export const useScanDeviceUpdates = () => {
  const mutationFetcher = useMutationFetcher<ScanDevice, ScanDeviceUpdateRequest>();
  const { apiUrl } = useEnvironment();

  const updateScanDevices = async (ids: string[], payload: ScanDeviceUpdateRequest) => {
    const promises = ids.map((id) => {
      return mutationFetcher(
        [
          `${apiUrl}/devices/${id}`,
          {
            method: 'PATCH',
          },
        ],
        { arg: payload },
      );
    });

    const responses = await Promise.allSettled(promises);
    const results: {
      succeeded: string[];
      failed: string[];
      errors: Set<string>;
    } = {
      succeeded: [],
      failed: [],
      errors: new Set(),
    };

    for (const [i, response] of responses.entries()) {
      if (response.status === 'fulfilled') {
        results.succeeded.push(ids[i]);
      } else {
        results.errors.add(response.reason.message);
        results.failed.push(ids[i]);
      }
    }

    return results;
  };

  return {
    updateScanDevices,
  };
};
