import { Button, Grid } from '@mui/material';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';

import { allSelectOption } from '@components/Form';
import { SearchFilterAccordion } from '@components/Util';
import { useSearchFilterForm } from '@hooks/useSearchFilterForm';
import type { TableFilters } from '@hooks/useTableFilterSort';

import { SearchFilterControlledTextField } from './SearchFilterControlledTextField';
import { SearchFilterControlledVrsTypeSelect } from './SearchFilterControlledVrsTypeSelect';

export interface SearchFilterFormValues {
  q?: string;
  type?: string;
}

const defaultValues: SearchFilterFormValues = {
  q: '',
  type: allSelectOption.id,
};

export interface VrsAccountsTableSearchFilterProps {
  initialValues?: Partial<SearchFilterFormValues>;
  onChange: (values: TableFilters) => void;
}

export const VrsAccountsTableSearchFilter: FC<VrsAccountsTableSearchFilterProps> = ({
  initialValues,
  onChange,
}) => {
  const { control, ...useFormProps } = useForm<SearchFilterFormValues>({
    defaultValues: { ...defaultValues, ...initialValues },
  });

  const {
    debouncedOnChangeHandler,
    numberOfActiveFilters,
    onChangeHandler,
    onResetButtonClickHandler,
  } = useSearchFilterForm<SearchFilterFormValues>({
    defaultValues,
    onChange,
    ...useFormProps,
  });

  return (
    <SearchFilterAccordion numberOfActiveFilters={numberOfActiveFilters}>
      <form>
        <Grid container spacing={2}>
          <Grid item lg={8} md={7} sm={6} xs={12}>
            <SearchFilterControlledTextField
              controllerProps={{ control, name: 'q' }}
              textFieldProps={{
                label: 'Search by description or username',
                onChange: debouncedOnChangeHandler,
              }}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <SearchFilterControlledVrsTypeSelect
              controllerProps={{ control, name: 'type' }}
              textFieldProps={{
                label: 'VRS Type',
                onChange: onChangeHandler,
              }}
            />
          </Grid>
          <Grid flex={1} item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onResetButtonClickHandler}>Clear</Button>
          </Grid>
        </Grid>
      </form>
    </SearchFilterAccordion>
  );
};
