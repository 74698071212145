import { useMemo } from 'react';
import useSWRMutation from 'swr/mutation';

import { useEnvironment } from '@hooks/useEnvironment';
import type { ScanDevice, ScanDeviceActions, ScanDeviceUpdateRequest } from 'types/api';

import { MutationFetcherKey, useMutationFetcher } from '../useMutationFetcher';

const useScanDeviceAction = (action: ScanDeviceActions, id?: string) => {
  const { apiUrl, isProd } = useEnvironment();
  const mutationFetcher = useMutationFetcher<ScanDevice, ScanDeviceUpdateRequest>();
  const swrMutation = useSWRMutation<ScanDevice, Error, MutationFetcherKey>(
    id ? [`${apiUrl}/devices/${id}/${action}`, { method: 'POST' }] : null,
    mutationFetcher,
  );

  return {
    ...swrMutation,
    // In production only allow the restart and shutdown actions
    trigger:
      isProd || action === 'update'
        ? swrMutation.trigger
        : () => {
            throw new Error('Shutdown a scan device is not allowed in non production environments');
          },
  };
};

export const useScanDeviceActions = (id?: string) => {
  const restart = useScanDeviceAction('restart', id);
  const shutdown = useScanDeviceAction('shutdown', id);
  const update = useScanDeviceAction('update', id);

  const actions = useMemo(
    () => ({
      restart,
      shutdown,
      update,
    }),
    [id],
  );

  return actions;
};
