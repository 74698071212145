import { Box, CircularProgress } from '@mui/material';
import type { FC } from 'react';

import * as classes from './CenteredLoading.module.css';

export const CenteredLoading: FC = () => {
  return (
    <Box className={classes.wrapper}>
      <CircularProgress data-testid="centered-loading-spinner" />
    </Box>
  );
};
