import type { GridColDef, GridSortDirection } from '@mui/x-data-grid';

import type { TableProps } from '@components/Table';
import type { FilterSortProps } from '@hooks/useTableFilterSort';

export const initialTableFilterSortArgs: Partial<FilterSortProps> = {
  sort: { field: 'name', sort: 'asc' as GridSortDirection },
};

export const columns: GridColDef[] = [
  {
    disableColumnMenu: true,
    field: 'name',
    filterable: false,
    headerName: 'Name',
    hideable: false,
    minWidth: 200,
    sortable: true,
    flex: 1,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'mountpoint',
    filterable: false,
    headerName: 'Mountpoint',
    sortable: true,
    width: 200,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'serverAddress',
    filterable: false,
    headerName: 'Server Address',
    sortable: true,
    width: 180,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'serverPort',
    filterable: false,
    headerName: 'Server Port',
    sortable: true,
    width: 110,
    type: 'string',
  },
  {
    disableColumnMenu: true,
    field: 'isDefault',
    filterable: false,
    headerName: 'Default',
    sortable: true,
    width: 80,
    type: 'string',
    renderCell: ({ value }) => (value ? 'Yes' : 'No'),
  },
];

export const columnVisibilityModelDesktop: TableProps['columnVisibilityModel'] = {};

export const columnVisibilityModelMobile: TableProps['columnVisibilityModel'] = {
  mountpoint: true,
  serverAddress: false,
  serverPort: false,
};
