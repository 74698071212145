import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  Button,
  Dialog,
  LinearProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  Alert,
  AlertTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import type { DeepScanVersion } from 'types/api';

import { DeepScanVersionControlledCheckbox } from './DeepScanVersionCheckbox';
import { DeepScanVersionControlledTextField } from './DeepScanVersionControlledTextField';
import * as classes from './DeepScanVersionFormDialog.module.css';

export type DeepScanVersionFormValuesAfterSubmit = Partial<Omit<DeepScanVersion, 'id'>>;
export type DeepScanVersionFormValues = DeepScanVersionFormValuesAfterSubmit;

const initialValues: DeepScanVersionFormValues = {
  buildVersion: '',
  hasCriticalBug: false,
  shortVersion: '',
};

export interface DeepScanVersionFormDialogProps {
  error?: Error;
  onClose: () => void;
  onOpen: () => void;
  onSubmit: (values: DeepScanVersionFormValues) => void;
  isLoadingDeepScanVersion?: boolean;
  isOpen: boolean;
  deepScanVersion: Partial<DeepScanVersion>;
  deepScanVersionFetchError?: Error;
}

export const DeepScanVersionFormDialog: React.FC<DeepScanVersionFormDialogProps> = ({
  error,
  isOpen,
  isLoadingDeepScanVersion,
  onClose,
  onOpen,
  onSubmit,
  deepScanVersionFetchError,
  deepScanVersion,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<DeepScanVersionFormValues>({
    defaultValues: { ...initialValues, ...deepScanVersion },
    reValidateMode: 'onSubmit',
  });

  React.useEffect(() => {
    reset({ ...initialValues, ...deepScanVersion });
  }, [deepScanVersion, reset]);

  const isEditing = deepScanVersion.id !== undefined;
  const isLoading = isSubmitting || isLoadingDeepScanVersion;
  const isInputDisabled = isEditing || isLoading;
  const displayError = (error as Error) || deepScanVersionFetchError;

  return (
    <div>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button onClick={onOpen} variant="outlined">
            Create DeepScan Version
          </Button>
        </Grid>
      </Grid>
      <Dialog disableEscapeKeyDown fullScreen={fullScreen} fullWidth maxWidth="md" open={isOpen}>
        {isLoading && <LinearProgress />}
        <DialogTitle sx={{ display: 'flex' }}>
          <span className={classes.title}>
            {isEditing ? 'Edit DeepScan Version' : 'Create DeepScan Version'}
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {displayError && (
            <Alert severity="error" variant="standard">
              <AlertTitle>{displayError.toString()}</AlertTitle>
            </Alert>
          )}
          <form id="vrs-type-form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container marginTop={0.5} spacing={2}>
              <Grid item md={6} xs={12}>
                <DeepScanVersionControlledTextField
                  controllerProps={{
                    control,
                    name: 'shortVersion',
                  }}
                  textFieldProps={{
                    disabled: isInputDisabled,
                    label: 'Short Version',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DeepScanVersionControlledTextField
                  controllerProps={{
                    control,
                    name: 'buildVersion',
                  }}
                  textFieldProps={{
                    disabled: isInputDisabled,
                    label: 'Build Version',
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DeepScanVersionControlledCheckbox
                  checkboxProps={{
                    disabled: isLoading,
                    helperText: 'Mark this DeepScan Version for having a critical bug.',
                    label: 'Has Critical Bug',
                  }}
                  controllerProps={{
                    control,
                    name: 'hasCriticalBug',
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions sx={{ padding: (theme) => (fullScreen ? theme.spacing(3) : undefined) }}>
          <Button disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isLoading}
            form="vrs-type-form"
            type="submit"
            variant="outlined"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
