import { Box, Grid } from '@mui/material';
import type { GridRowParams } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import type { FC } from 'react';

import { DeepScanVersionFormDialogContainer } from '@components/DeepScanVersionForm';
import { PhasedRolloutFormDialogContainer } from '@components/PhasedRolloutForm';
import { Table } from '@components/Table';
import type { TableProps } from '@components/Table';
import { useDeepScanVersions } from '@hooks/api';
import { useColumnVisibilityModel } from '@hooks/useColumnVisibilityModel';
import { useTableFilterSort } from '@hooks/useTableFilterSort';
import type { DeepScanVersion } from 'types/api';

import {
  columns,
  initialTableFilterSortArgs,
  columnVisibilityModelDesktop,
  columnVisibilityModelMobile,
} from './constants';

export interface DeepScanVersionsTableProps {
  syncWithQueryParams?: boolean;
}

export const DeepScanVersionsTable: FC<DeepScanVersionsTableProps> = ({
  syncWithQueryParams = false,
}) => {
  const [deepScanVersionId, setDeepScanVersionId] = useState<string | undefined>(undefined);
  const tableFilterSort = useTableFilterSort(syncWithQueryParams)(initialTableFilterSortArgs);
  const { filters, page, size, sort } = tableFilterSort;
  const { data, error, isLoading, mutate } = useDeepScanVersions(
    {
      filters,
      page,
      size,
      sort,
    },
    {
      refreshInterval: 60000,
    },
  );
  const columnVisibilityModel = useColumnVisibilityModel(
    columnVisibilityModelMobile,
    columnVisibilityModelDesktop,
  );

  const onRowClick: TableProps['onRowClick'] = useCallback(
    (rowData: GridRowParams<DeepScanVersion>) => {
      setDeepScanVersionId(rowData.row.id);
    },
    [setDeepScanVersionId],
  );

  const onClose = useCallback(() => {
    setDeepScanVersionId(undefined);
    mutate();
  }, [setDeepScanVersionId, mutate]);

  return (
    <Box
      sx={(theme) => ({
        height: `calc(100vh - ${theme.spacing(26.5)})`,
        [theme.breakpoints.down('md')]: {
          height: `calc(100vh - ${theme.spacing(34.5)})`,
        },
        [theme.breakpoints.down('sm')]: {
          height: `calc(100vh - ${theme.spacing(34.5)})`,
        },
        [theme.breakpoints.only('xs')]: {
          height: `calc(100vh - ${theme.spacing(38.5)})`,
        },
      })}
    >
      <Grid
        alignItems={{
          xs: 'flex-end',
          md: 'center',
        }}
        container
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        justifyContent="flex-end"
        marginBottom={2}
        spacing={1}
      >
        <Grid flex={1} item width="100%">
          <PhasedRolloutFormDialogContainer onClose={onClose} />
        </Grid>
        <Grid item sx={{ m: 1 }}>
          <DeepScanVersionFormDialogContainer
            deepScanVersionId={deepScanVersionId}
            onClose={onClose}
          />
        </Grid>
      </Grid>
      <Table
        columnVisibilityModel={columnVisibilityModel}
        columns={columns}
        disableSelectionOnClick
        error={error as Error}
        loading={isLoading}
        localStorageSettingsKey="deep-scan-versions-table-settings"
        onRowClick={onRowClick}
        rowCount={data?.totalElements}
        rows={data?.content}
        tableFilterSort={tableFilterSort}
      />
    </Box>
  );
};
