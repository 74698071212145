import { useMemo } from 'react';

import type { ControlledSelectOption } from '@components/Form';

import { useMdmGroups } from './api';

export const useMdmGroupSelect = (
  buildOptionsFunc?: (options?: ControlledSelectOption[]) => (ControlledSelectOption & {
    selectable?: boolean;
    changeable?: boolean;
  })[],
) => {
  const { data: mdmGroups } = useMdmGroups();

  return useMemo(() => {
    const options =
      mdmGroups
        ?.filter((mdmGroup) => mdmGroup.selectable === true)
        .map((mdmGroup) => ({ ...mdmGroup, id: mdmGroup.id.toString() })) ?? [];

    return {
      mdmGroups,
      options: buildOptionsFunc ? buildOptionsFunc(options) : options,
    };
  }, [mdmGroups]);
};
