import { useMediaQuery, useTheme } from '@mui/material';

import type { TableProps } from '@components/Table';

export const useColumnVisibilityModel = (
  mobileOption: TableProps['columnVisibilityModel'],
  desktopOption: TableProps['columnVisibilityModel'],
) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return isSmallScreen ? mobileOption : desktopOption;
};
