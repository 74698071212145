import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import SystemUpdateRoundedIcon from '@mui/icons-material/SystemUpdateRounded';
import { Grid, Tooltip } from '@mui/material';
import type { FC } from 'react';

import type { DeepScanVersionStatus, ScanDevice } from 'types/api';

export interface DeepScanVersionStatusProps {
  deepScanVersionStatus: ScanDevice['deepScanVersionStatus'];
  showText?: boolean;
}

export const DeepScanVersionStatusComponent: FC<DeepScanVersionStatusProps> = ({
  deepScanVersionStatus,
  showText,
}) => {
  if (!deepScanVersionStatus) return null;

  const { icon, statusText } = getStatusTextAndIcon(deepScanVersionStatus);
  const title = `${statusText}`;

  if (showText) {
    return (
      <Grid alignItems="center" container spacing={2}>
        <Grid item>{icon}</Grid>
        <Grid item>{title}</Grid>
      </Grid>
    );
  }

  return (
    <Tooltip data-testid="tooltip" title={title}>
      {icon}
    </Tooltip>
  );
};

const getStatusTextAndIcon = (remoteUpdateStatus: DeepScanVersionStatus) => {
  switch (remoteUpdateStatus) {
    case 'UPDATE_AVAILABLE':
      return {
        statusText: 'Update available',
        icon: <SystemUpdateRoundedIcon color="info" data-testid="update-available-icon" />,
      };
    case 'UPDATE_IN_PROGRESS':
      return {
        statusText: 'Update in progress',
        icon: (
          <CachedRoundedIcon
            color="info"
            data-testid="update-in-progress-icon"
            sx={{
              animation: 'spin 2s linear infinite',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(360deg)',
                },
                '100%': {
                  transform: 'rotate(0deg)',
                },
              },
            }}
          />
        ),
      };
    case 'UP_TO_DATE':
      return {
        statusText: 'Up to date',
        icon: <CheckCircleRoundedIcon color="primary" data-testid="up-to-date-latest-icon" />,
      };
    default:
      return {
        statusText: 'Unknown',
        icon: <HelpRoundedIcon color="disabled" data-testid="unknown-icon" />,
      };
  }
};
