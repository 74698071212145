import type { FC } from 'react';

import { ControlledTextField } from '@components/Form';
import type { ControlledTextFieldProps } from '@components/Form';

import type { VrsTypeFormValues } from './VrsTypeFormDialog';

export type VrsTypeControlledTextFieldProps = ControlledTextFieldProps<VrsTypeFormValues>;

export const VrsTypeControlledTextField: FC<VrsTypeControlledTextFieldProps> = ({
  controllerProps,
  textFieldProps,
}) => {
  return (
    <ControlledTextField
      controllerProps={{ rules: { required: 'This field is required' }, ...controllerProps }}
      textFieldProps={{ helperText: ' ', ...textFieldProps }}
    />
  );
};
