import ExternalLinkIcon from '@mui/icons-material/Launch';
import { Grid } from '@mui/material';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { CenteredLoading } from '@components/Util';
import { BackButton } from '@components/Util/BackButton';
import { useMdmInfo, useScanDevice } from '@hooks/api';
import { useDatadog } from '@hooks/useDatadog';
import { useDeviceScansDashboard } from '@hooks/useDeviceScansDashboard';

import { ExternalLinkButton } from './ExternalLinkButton';
import { GeneralInformationSection } from './Sections/GeneralInformationSection';
import { ProfileSection } from './Sections/ProfileSections';
import { ResetProcessesSection } from './Sections/ResetProcessSection';
import { ScanDeviceActionButtons } from './Sections/ScanDeviceActionButtons';
import { TechnicalInformationSection } from './Sections/TechnicalInformationSection';
import { UsageDataSection } from './Sections/UsageDataSection';

export const ScanDeviceDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: mdmInfo,
    isLoading: mdmInfoIsLoading,
    mutate: mutateMdmInfo,
  } = useMdmInfo(id as string, {
    // Refresh every 2 minutes
    refreshInterval: 120000,
  });
  const {
    data: scanDeviceDetail,
    isLoading,
    mutate,
  } = useScanDevice(id as string, {
    // Refresh every 10 minutes
    refreshInterval: 600000,
  });
  const { getDataDogLink } = useDatadog();
  const { getDeviceScansDashboardUrl } = useDeviceScansDashboard();

  if (isLoading || !scanDeviceDetail) {
    return <CenteredLoading />;
  }

  const datadogLink = getDataDogLink(scanDeviceDetail.udid);
  const deviceScansDashboardLink = getDeviceScansDashboardUrl(scanDeviceDetail.id);

  return (
    <div>
      <Grid alignItems="center" container direction="row" marginBottom={3} spacing={1}>
        <Grid flex={1} flexWrap="nowrap" item>
          <BackButton defaultRoute="/devices">back to the overview</BackButton>
        </Grid>
        <Grid item>
          <Grid alignItems="center" container spacing={1}>
            <Grid item>
              <ScanDeviceActionButtons scanDeviceId={scanDeviceDetail.id} />
            </Grid>
            <Grid item>
              <ExternalLinkButton href={datadogLink} startIcon={<ExternalLinkIcon />}>
                Datadog
              </ExternalLinkButton>
            </Grid>
            <Grid item>
              <ExternalLinkButton href={deviceScansDashboardLink} startIcon={<ExternalLinkIcon />}>
                Device Scans
              </ExternalLinkButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <GeneralInformationSection
            mdmInfo={mdmInfo}
            mdmInfoIsLoading={mdmInfoIsLoading}
            scanDeviceDetail={scanDeviceDetail}
          />
        </Grid>
        <Grid item xs={12}>
          <ProfileSection
            mdmInfo={mdmInfo}
            mdmInfoIsLoading={mdmInfoIsLoading}
            mutateScanDeviceDetail={mutate}
            mutateScanDeviceMdmInfo={mutateMdmInfo}
            scanDeviceDetail={scanDeviceDetail}
          />
        </Grid>
        <Grid item xs={12}>
          <UsageDataSection
            mdmInfo={mdmInfo}
            mdmInfoIsLoading={mdmInfoIsLoading}
            scanDeviceDetail={scanDeviceDetail}
          />
        </Grid>
        <Grid item xs={12}>
          <TechnicalInformationSection
            mdmInfo={mdmInfo}
            mdmInfoIsLoading={mdmInfoIsLoading}
            mutateScanDeviceDetail={mutate}
            scanDeviceDetail={scanDeviceDetail}
          />
        </Grid>
        <Grid item xs={12}>
          <ResetProcessesSection scanDeviceDetail={scanDeviceDetail} />
        </Grid>
      </Grid>
    </div>
  );
};
