import { useCallback } from 'react';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';

import { ControlledSelect } from '@components/Form';
import { UseScanDeviceMutate, useScanDeviceUpdate } from '@hooks/api';
import { useInPlaceEdit } from '@hooks/useInPlaceEdit';
import { useVrsTypeSelect } from '@hooks/useVrsTypeSelect';
import type { ScanDeviceDetail } from 'types/api';

import { InPlaceEdit } from './InPlaceEdit';

interface FormValues {
  vrsType: string;
}

export interface VrsAccountInPlaceChangeButtonProps {
  mutateScanDeviceDetail: UseScanDeviceMutate;
  scanDeviceDetail: ScanDeviceDetail;
}

export const VrsAccountInPlaceChangeButton: FC<VrsAccountInPlaceChangeButtonProps> = ({
  mutateScanDeviceDetail,
  scanDeviceDetail,
}) => {
  const { trigger, isMutating } = useScanDeviceUpdate(scanDeviceDetail.id);
  const { options } = useVrsTypeSelect();

  const update = useCallback(
    async (data: FormValues) => {
      await trigger(data);

      mutateScanDeviceDetail((current) => {
        if (!current) return current;

        const vrsType = options.find((vrsTypeOption) => vrsTypeOption.id === data.vrsType);

        return {
          ...current,
          vrsType: vrsType || current.vrsType,
        };
      });
    },
    [mutateScanDeviceDetail, options, trigger],
  );

  const { isEditing, onSubmit, setIsEditing } = useInPlaceEdit<FormValues>({
    update,
  });

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { vrsType: scanDeviceDetail.vrsType.id },
  });

  const onChangeHandler = handleSubmit(onSubmit);

  return (
    <InPlaceEdit
      isEditing={isEditing}
      isUpdating={isMutating}
      selectedValueText={scanDeviceDetail.vrsType.name}
      setIsEditing={setIsEditing}
    >
      <ControlledSelect
        controllerProps={{ control, name: 'vrsType' }}
        options={options}
        textFieldProps={{
          onChange: onChangeHandler,
          size: 'small',
        }}
      />
    </InPlaceEdit>
  );
};
