import type { PaletteMode } from '@mui/material';
import type { ThemeOptions } from '@mui/material/styles';

import { darkThemeOptions } from './dark-theme-options';
import { lightThemeOptions } from './light-theme-options';

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
  const themeOptions = mode === 'light' ? lightThemeOptions : darkThemeOptions;

  return themeOptions;
};
