import { Link as MuiLink } from '@mui/material';
import type { LinkProps as MuiLinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';

export const Link = ({ children, ...rest }: MuiLinkProps & RouterLinkProps) => {
  return (
    <MuiLink component={RouterLink} {...rest}>
      {children}
    </MuiLink>
  );
};
