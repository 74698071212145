import type { FC } from 'react';

import { VrsTypesTable } from '@components/VrsTypeTable';

export const VrsTypesListPage: FC = () => {
  return (
    <div data-testid="vrs-types-list-page">
      <VrsTypesTable syncWithQueryParams />
    </div>
  );
};
