import type { FC } from 'react';

import { addPleaseSelectOption, ControlledSelect } from '@components/Form';
import type { ControlledSelectProps } from '@components/Form';
import { useVrsTypeSelect } from '@hooks/useVrsTypeSelect';

import type { EditScanDeviceFormValues } from './EditScanDeviceFooter';

export type ControlledVrsTypeselectProps = Omit<
  ControlledSelectProps<EditScanDeviceFormValues>,
  'options'
>;

export const ControlledVrsTypeSelect: FC<ControlledVrsTypeselectProps> = ({
  textFieldProps,
  ...props
}) => {
  const { options } = useVrsTypeSelect(addPleaseSelectOption);

  return (
    <ControlledSelect
      {...props}
      options={options}
      textFieldProps={{
        ...textFieldProps,
        size: 'small',
        sx: { minWidth: (theme) => theme.spacing(22.5) },
      }}
    />
  );
};
