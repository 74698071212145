import type { FC } from 'react';

import { ControlledSelect, addAllSelectOption } from '@components/Form';
import type { ControlledSelectProps } from '@components/Form';
import { useVrsTypeSelect } from '@hooks/useVrsTypeSelect';

import type { SearchFilterFormValues } from './VrsAccountsTableSearchFilter';

export type ControlledVrsTypeSelectProps = Omit<
  ControlledSelectProps<SearchFilterFormValues>,
  'options'
>;

export const SearchFilterControlledVrsTypeSelect: FC<ControlledVrsTypeSelectProps> = ({
  textFieldProps,
  ...props
}) => {
  const { options } = useVrsTypeSelect(addAllSelectOption);

  return (
    <ControlledSelect
      {...props}
      options={options}
      textFieldProps={{ ...textFieldProps, size: 'small' }}
    />
  );
};
