import { type SwrConfig, useScanDeviceApiGetQuery } from './useScanDeviceApiGetQuery';

export const useScanDeviceApiDetailQuery = <ResponseType>(apiPath: string) => {
  return (id?: string, swrConfig?: SwrConfig<ResponseType>) => {
    const urlPath = id ? `${apiPath}/${id}` : undefined;
    const swrResponse = useScanDeviceApiGetQuery<ResponseType>(urlPath, swrConfig);

    return swrResponse;
  };
};
