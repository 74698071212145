import type { MutationFetcher, SWRMutationResponse } from 'swr/mutation';

import { useJsonFetcher } from './useJsonFetcher';

export type MutationFetcherKey = [url: string, fetchInit?: RequestInit] | null;
export type UseMutation<Data, Error, ExtraArg = void> = SWRMutationResponse<
  Data,
  Error,
  ExtraArg,
  MutationFetcherKey
>;

export const useMutationFetcher = <ResponseType, MutationFetcherArgs = void>() => {
  const jsonFetcher = useJsonFetcher<ResponseType>();

  const mutationFetcher: MutationFetcher<ResponseType, MutationFetcherArgs, MutationFetcherKey> = (
    [url, fetchInit],
    { arg },
  ) => {
    return jsonFetcher([
      url,
      {
        ...fetchInit,
        body: JSON.stringify(arg),
        headers: {
          'Content-Type': 'application/json',
          ...fetchInit?.headers,
        },
      },
    ]);
  };

  return mutationFetcher;
};
