import type { FC } from 'react';
import { useCallback } from 'react';

import { useHardwareVersion, useHardwareVersionDelete, useHardwareVersionUpsert } from '@hooks/api';
import { useFormDialog } from '@hooks/useFormDialog';
import type { HardwareVersion } from 'types/api';

import {
  HardwareVersionFormDialog,
  HardwareVersionFormValuesAfterSubmit,
} from './HardwareVersionFormDialog';
import type { HardwareVersionFormValues } from './HardwareVersionFormDialog';

export interface HardwareVersionFormDialogContainerProps {
  onClose: () => void;
  hardwareVersionId?: string;
}

export const HardwareVersionFormDialogContainer: FC<HardwareVersionFormDialogContainerProps> = ({
  onClose,
  hardwareVersionId,
}) => {
  const { trigger: triggerUpsert } = useHardwareVersionUpsert(hardwareVersionId);
  const { trigger: triggerDelete } = useHardwareVersionDelete(hardwareVersionId);
  const {
    data: hardwareVersion,
    error: fetchError,
    isLoading,
  } = useHardwareVersion(hardwareVersionId, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const {
    formError,
    handleClose,
    handleOpen,
    newEntity: newHardwareVersion,
    handleDelete,
    handleSubmit,
    open,
  } = useFormDialog<
    HardwareVersion,
    HardwareVersionFormValues,
    HardwareVersionFormValuesAfterSubmit
  >({
    deleteCb: triggerDelete,
    onClose,
    upsertCb: triggerUpsert,
    entityId: hardwareVersionId,
  });

  const error = formError || (fetchError as Error);
  const isEditing = hardwareVersionId !== undefined;

  // Hardware versions should not be updated, so the only value for a patch
  // request is the isDefault setting
  const onSubmit = useCallback(
    (values: HardwareVersionFormValues) => {
      const valuesToSubmit = isEditing ? { isDefault: values.isDefault } : values;

      handleSubmit(valuesToSubmit);
    },
    [isEditing, handleSubmit],
  );

  // Use the key prop to automaticly initialize the form in the dialog when the
  // hardware version changes
  return (
    <HardwareVersionFormDialog
      error={error}
      hardwareVersion={hardwareVersion || newHardwareVersion}
      isLoadingVrs={isLoading}
      isOpen={open}
      key={hardwareVersion?.id}
      onClose={handleClose}
      onDelete={handleDelete}
      onOpen={handleOpen}
      onSubmit={onSubmit}
    />
  );
};
