import type { FC } from 'react';

import { ControlledSelect } from '@components/Form';
import type { ControlledSelectProps } from '@components/Form';
import { useVrsTypeSelect } from '@hooks/useVrsTypeSelect';

import type { VrsAccountFormValues } from '.';

export type VrsAccountControlledTypeSelectProps = Omit<
  ControlledSelectProps<VrsAccountFormValues>,
  'options'
>;

export const VrsAccountControlledTypeSelect: FC<VrsAccountControlledTypeSelectProps> = ({
  controllerProps,
  textFieldProps,
}) => {
  const { options: vrsTypes } = useVrsTypeSelect();

  return (
    <ControlledSelect
      controllerProps={{ ...controllerProps, rules: { required: 'This field is required' } }}
      options={vrsTypes ?? []}
      textFieldProps={{ helperText: ' ', ...textFieldProps }}
    />
  );
};
