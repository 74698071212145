import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Menu, MenuItem, ListItemText, Avatar } from '@mui/material';
import type { FC, MouseEvent } from 'react';
import { useState } from 'react';

import * as classes from './AuthMenu.module.css';

export enum AuthMenuActions {
  LOGOUT = 'LOGOUT',
}

export const authMenuItems = [
  {
    text: 'Logout',
    action: AuthMenuActions.LOGOUT,
  },
];

export const AuthMenu: FC = () => {
  const { user, logout, isAuthenticated } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (action: string) => {
    return () => {
      switch (action) {
        case AuthMenuActions.LOGOUT:
          logout({ returnTo: window.location.origin });
          break;
      }

      handleClose();
    };
  };

  return (
    <div>
      <IconButton
        aria-controls={open ? 'auth-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        color="inherit"
        data-testid="auth-button"
        disabled={!isAuthenticated}
        id="auth-button"
        onClick={handleClick}
      >
        <Avatar alt={user?.name} className={classes.avatar} src={user?.picture} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        aria-labelledby="auth-button"
        data-testid="auth-menu"
        id="auth-menu"
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {authMenuItems.map(({ text, action }) => (
          <MenuItem key={text} onClick={handleMenuItemClick(action)}>
            <ListItemText>{text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
