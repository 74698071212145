import { Box, LinearProgress, type LinearProgressProps, Typography } from '@mui/material';
import type { FC } from 'react';

export interface BatteryIndicatorProps {
  value?: string;
}

const getColor = (value: number): LinearProgressProps['color'] => {
  if (value <= 20) {
    return 'error';
  }

  if (value <= 40) {
    return 'warning';
  }

  return 'primary';
};

export const BatteryIndicator: FC<BatteryIndicatorProps> = ({ value }) => {
  if (!value) {
    return null;
  }

  const batteryLevel = parseInt(value.replaceAll('%', ''), 10);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        width: (theme) => theme.spacing(9.5),
      }}
    >
      <Box sx={(theme) => ({ marginRight: theme.spacing(2), minWidth: theme.spacing(3) })}>
        <Typography>{batteryLevel}%</Typography>
      </Box>
      <Box width="100%">
        <LinearProgress
          color={getColor(batteryLevel)}
          sx={{
            height: (theme) => theme.spacing(1.5),
            width: '100%',
          }}
          value={batteryLevel}
          variant="determinate"
        />
      </Box>
    </Box>
  );
};
