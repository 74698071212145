import {
  Switch,
  type SwitchProps,
  FormControl,
  type FormControlProps,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { Controller, type FieldValues, type ControllerProps } from 'react-hook-form';

import { FormErrorMessage } from './FormErrorMessage';

export interface ControlledSwitchProps<FormValuesType extends FieldValues> {
  controllerProps: Omit<ControllerProps<FormValuesType>, 'render'>;
  formControlProps?: FormControlProps;
  switchProps: SwitchProps & { helperText?: string; label?: string; ariaLabel?: string };
}

export const ControlledSwitch = <FormValuesType extends FieldValues>({
  controllerProps,
  formControlProps,
  switchProps: { helperText = '', label = '', ariaLabel = '', ...switchProps },
}: ControlledSwitchProps<FormValuesType>): JSX.Element => (
  <Controller
    {...controllerProps}
    render={({ field, fieldState: { error } }) => (
      <FormControl
        error={error !== undefined}
        fullWidth
        required={controllerProps.rules?.required !== undefined}
        {...formControlProps}
      >
        <FormControlLabel
          control={
            <Switch
              aria-label={ariaLabel}
              required={controllerProps.rules?.required !== undefined}
              {...field}
              {...switchProps}
              checked={field.value}
              onChange={(event, checked) => {
                field.onChange(event);
                switchProps.onChange && switchProps.onChange(event, checked);
              }}
            />
          }
          label={label}
        />
        <FormHelperText>{error ? <FormErrorMessage error={error} /> : helperText}</FormHelperText>
      </FormControl>
    )}
  />
);
